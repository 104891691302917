import type { API, GenericApiResponse } from '@setplex/tria-api'
import type { HttpClient } from '../../../http'
import type { ApiOVPUserInfo, ApiOVPUserSessionData } from './index.h'

export function use(
  http: HttpClient,
  session: API['userSession'],
  _api: API
): void {
  // GET /api/web/users/current
  session.checkStatusFx.use(async () => {
    const json = await http.get<GenericApiResponse<ApiOVPUserSessionData>>(
      '/users/current'
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in session.checkStatusFx')
    }

    return json.payload
  })

  // GET /api/web/users/favoritesCount
  session.getFavoritesCountFx.use(async () => {
    const json = await http.get<GenericApiResponse<number>>(
      '/users/favoritesCount'
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in session.getFavoritesCountFx')
    }

    return json.payload
  })

  // GET /api/web/users/info
  session.getInfoFx.use(async () => {
    const json = await http.get<GenericApiResponse<ApiOVPUserInfo>>(
      '/users/info'
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in session.getInfoFx')
    }

    return json.payload
  })
}
