import { createEffect, type Effect } from 'effector'
import type { OVPSessionData, OVPUserInfo, OVPUserSessionData } from './index.h'

export type { OVPSessionData, OVPUserInfo, OVPUserSessionData }

export const checkStatusFx: Effect<void, OVPUserSessionData> = createEffect()

export const getFavoritesCountFx: Effect<void, number> = createEffect()

export const getInfoFx: Effect<void, OVPUserInfo> = createEffect()
