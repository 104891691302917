import { createEffect } from 'effector'
import { type HeaderFxParameter } from '../../index.h'
import { http } from '../http'
import { LaunchData } from './index.h'

export const checkFx = createEffect<HeaderFxParameter, LaunchData, Error>(
  async (params) => {
    const { headers = {} } = params ?? {}

    const json = await http
      .get('launch', {
        headers,
      })
      .json()

    if (!json) {
      throw new Error('Empty answer in launch.checkFx')
    }

    return json
  }
)
