type UndefinedKeys<T> = {
  [K in keyof T]: [undefined] extends [T[K]] ? K : never
}[keyof T]

type PickUndefined<T> = Pick<T, UndefinedKeys<T>>
type OmitUndefined<T> = Pick<T, Exclude<keyof T, UndefinedKeys<T>>>

type Optional<T> = OmitUndefined<T> & Partial<PickUndefined<T>>

export const defined = <T>(obj: T): Optional<T> => {
  const result: T = {} as T
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (obj[key] !== undefined) {
        result[key] = obj[key]
      }
    }
  }
  return result
}
