import { A } from '@mobily/ts-belt'
import { createStore, sample } from 'effector'
import { type Range } from '../index.h'
import { channelEpgId } from '../lib'
import { fuseRanges, isSameRange } from '../lib/ranges'
import { fetchEpgInfoFx } from './fetch'

// Rename to loadedRanges
// already loaded epg ranges by epg id
export const $loadedEpgRanges = createStore<[Map<string, Range[]>]>([new Map()])

// optimistically mark epg range as loaded on request, to prevent duplicate requests
sample({
  clock: fetchEpgInfoFx,
  source: $loadedEpgRanges,
  fn: ([programs], { channels, period }) => {
    const epgIds = A.uniq(A.map(channels, channelEpgId))
    for (const id of epgIds) {
      const ranges = programs.get(id) ?? []
      ranges.push([period.start, period.end])
      programs.set(id, ranges)
    }

    return [programs] as const
  },
  target: $loadedEpgRanges,
})

// fuse loaded ranges in case of success, to reduse calculations later
sample({
  clock: fetchEpgInfoFx.done,
  source: $loadedEpgRanges,
  fn: ([programs], { params: { channels } }) => {
    const epgIds = A.uniq(A.map(channels, channelEpgId))
    for (const id of epgIds) {
      const ranges = programs.get(id) ?? []
      programs.set(id, fuseRanges(ranges))
    }
    return [programs] as const
  },
  target: $loadedEpgRanges,
})

// remove mark of epg range as loaded on request failure
sample({
  clock: fetchEpgInfoFx.fail,
  source: $loadedEpgRanges,
  fn: ([programs], { params: { channels, period } }) => {
    const epgIds = A.uniq(A.map(channels, channelEpgId))
    for (const id of epgIds) {
      const ranges = programs.get(id) ?? []
      const index = ranges.findIndex(isSameRange(period))
      if (index !== -1) {
        ranges.splice(index, 1)
      }
      programs.set(id, ranges)
    }
    return [programs] as const
  },
  target: $loadedEpgRanges,
})
