import {
  createEvent,
  createStore,
  type EventCallable,
  type Store,
  type StoreWritable,
} from 'effector'

export const createMediaQueryStore = (query: string, defaults: boolean) => {
  const supportMatchMedia =
    typeof window !== 'undefined' && //
    typeof window.matchMedia !== 'undefined'

  if (!supportMatchMedia) {
    return createStore(defaults)
  }

  const mediaQueryList: MediaQueryList = window.matchMedia(query)

  const match: EventCallable<boolean> = createEvent()
  const $matches: StoreWritable<boolean> = createStore(mediaQueryList.matches) //
    .on(match, (_, payload) => payload)

  const onChange = (e: MediaQueryListEvent) => match(e.matches)

  // old deprecated method for older browsers
  // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
  if (mediaQueryList.addListener) {
    mediaQueryList.addListener(onChange)
  } else {
    mediaQueryList.addEventListener('change', onChange)
  }

  return $matches
}

const $media: StoreWritable<boolean> = createMediaQueryStore(
  '(min-width: 1440px)',
  true
)
export const $desktop: Store<'desktop' | ''> = $media.map((m) =>
  m ? 'desktop' : ''
)
