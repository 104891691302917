import { A } from '@mobily/ts-belt'
import { idEq } from '~/shared/helpers'
import { NO_EPG_ID, type EpgRequestParams, type Range } from '../index.h'
import { biggestCoverRange, unloadedRanges } from './ranges'

/**
 * The function checks whether the EPG for the requested period is already loaded
 * @param requested
 * @param loadedRanges
 */
export const narrowEpgRequestToNecessary = ({
  requested,
  loadedRanges,
}: {
  requested: EpgRequestParams
  loadedRanges: ReadonlyMap<string, Range[]>
}): EpgRequestParams => {
  const channels = requested.channels.slice()
  const periods = []

  // exclude channels, epg for which is already loaded for requested period
  for (const { id, channelEpgId = NO_EPG_ID } of requested.channels) {
    const ranges = loadedRanges.get(channelEpgId) ?? []
    const unloaded = unloadedRanges(ranges, requested.period)

    // if there are no unloaded ranges, then we don't need to fetch epg for that channel
    if (A.isEmpty(unloaded)) {
      const notNeededIndex = channels.findIndex(idEq(id))
      if (notNeededIndex !== -1) {
        channels.splice(notNeededIndex, 1)
      }
    }

    // otherwise, we need to fetch epg for that channel
    else {
      periods.push(...unloaded)
    }
  }

  return {
    channels, // it is ok, if we'll come to empty array here, it will be filtered out
    period: biggestCoverRange(periods),
  }
}
