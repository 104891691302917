import type { API, GenericApiResponse } from '@setplex/tria-api'
import type { HttpClient } from '../../http'
import type { ApiHomeRows } from './index.h'

const HOME_API = '/home-page'

export function use(http: HttpClient, home: API['home'], _api: API): void {
  // GET /api/web/home-page
  home.getFx.use(async () => {
    const json = await http.get<GenericApiResponse<ApiHomeRows>>(HOME_API)

    if (!json || !json.payload) {
      throw new Error('Empty answer in home.base.getFx')
    }

    return json.payload || {}
  })
}
