import { F } from '@mobily/ts-belt'

/*
 * Different useful object helpers
 */

type StringNumberRecord = Record<string | number, string | number>

/**
 * `id` getter
 */
export const id = <T extends { id: unknown }>(obj: T): T['id'] => obj.id

/**
 * `id` equality check
 */
export const idEq =
  <X>(id: X) =>
  <T extends { id?: X }>(obj: T) =>
    obj.id === id

/**
 * `sortOrder` getter
 * In case `sortOrder` is not defined - returns 0
 */
export const sortOrder = <T extends { sortOrder?: unknown }>(
  obj: T
): T['sortOrder'] => obj.sortOrder ?? 0

/**
 * Returns new object with keys of the given object as values,
 * and values of the given object, which are coerced to strings, as keys
 */
export function invertObj(obj: StringNumberRecord): StringNumberRecord {
  const result: StringNumberRecord = {}
  for (const [key, value] of Object.entries(obj)) {
    result[value] = key
  }
  return result
}

/**
 * Compares two objects by given key getter
 */
export const equalToBy =
  <T>(by: (item: T) => unknown = F.identity) =>
  (a: T) =>
  (b: T): boolean =>
    by(a) === by(b)
