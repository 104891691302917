/**
 * Function adds 'ga-'.
 * For example, function will return 'ga-play' if value was 'play
 */

export const addGoogleAnalyticsPrefix = (value: string) => {
  return `ga-${value}`
}

/**
 * Function adds 'ga-authorised-' or 'ga-nonauthorised-' prefix based on auth status.
 * For example, if auth is true and value is 'play', function will return 'ga-authorised-play'
 * If auth is false/null and value is 'play', function will return 'ga-nonauthorised-play'
 * @param value - The string value to add prefix to
 * @param auth - Boolean indicating if user is authorized. Null is treated as false
 * @returns String with appropriate prefix added
 */
export const addGoogleAnalyticsPrefixWithAuth = (
  value: string,
  auth: boolean | null
) => {
  return `ga-${auth ? 'authorised' : 'nonauthorised'}-${value}`
}
