import { api, type UsersPreferences } from '@setplex/tria-api'
import { attach, createStore } from 'effector'
import { produce } from 'immer'

export const getExtraFx = attach({
  effect: api.users.getExtraFx,
})

export const getOnboardingExtraFx = attach({
  effect: api.onboarding.getOnboardingExtraFx,
})

export const $languages = createStore<UsersPreferences['collections'] | null>(
  null
)
  .reset(
    getExtraFx,
    getOnboardingExtraFx,
    getExtraFx.fail,
    getOnboardingExtraFx.fail
  )
  .on([getExtraFx.doneData, getOnboardingExtraFx.doneData], (_, got) => {
    const { collections = [] } = got ?? {}
    return produce(collections, (draft) => {
      draft?.sort((a, b) => b.contentCount - a.contentCount)
    })
  })

export const $languagesAlphabeticallySorted = $languages.map((languages = []) =>
  produce(languages, (draft) => {
    draft?.sort((a, b) => a.title.localeCompare(b.title))
  })
)
