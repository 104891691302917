import { Loader } from '@/ui/components/Loader'
import { PageLoader } from '@setplex/arcane'
import { ThemeProvider } from './ThemeProvider'

Bootstrap.displayName = 'Bootstrap'
export function Bootstrap(): JSX.Element {
  return (
    <ThemeProvider>
      <Loader />
      <PageLoader />
    </ThemeProvider>
  )
}
