import { createEffect, type Effect } from 'effector'
import type { GenericApiResponse } from '../../interfaces/generic'
import type { OVPSupport } from './index.h'

export type { OVPSupport }

export const setContactDataFx: Effect<
  OVPSupport,
  void,
  GenericApiResponse<Error>
> = createEffect()
