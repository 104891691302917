import { model as flags } from '!/flags'
import { model as launch } from '!/launch'
import { firebaseSharedModel as firebase } from '@/firebase'
import { createEvent, sample, type EventCallable } from 'effector'
import { model as session } from '~/entities/session'
import { channelsFeature } from '~/features/epg/channels'
import { googlePalFeatureModel } from '~/features/google-pal'
import { preferences } from '~/features/preferences'
import { profile } from '~/features/profile'
import { model as clock } from '~/shared/clock'

export const init: EventCallable<void> = createEvent()

//
// application launch
//

sample({
  clock: init,
  target: launch.init,
})

// setup application clock
sample({
  clock: init,
  target: clock.setup,
})

// init flags and features
sample({
  clock: init,
  target: [flags.init, firebase.init],
})

// init session (get from BE)
sample({
  clock: init,
  target: session.init,
})

// init features
sample({
  clock: init,
  target: [googlePalFeatureModel.init, preferences.init, profile.init],
})

// init live
sample({
  clock: init,
  target: channelsFeature.init,
})
