import { createEffect, type Effect } from 'effector'
import type { GenericApiResponse } from '../../interfaces/generic'
import type { OVPPrivacyOptOut, OVPPrivacySensitive } from './index.h'

export type { OVPPrivacyOptOut, OVPPrivacySensitive }

export const setPrivacyOptOutFx: Effect<
  OVPPrivacyOptOut,
  void,
  GenericApiResponse<Error>
> = createEffect()

export const setPrivacySensitiveDataFx: Effect<
  OVPPrivacySensitive,
  void,
  GenericApiResponse<Error>
> = createEffect()
