import { PATH } from '@/constants'
import {
  formatBannerUrl,
  formatChannelLogoUrl,
  formatTitleForUrl,
} from '@/helpers'
import type { LiveChannel } from '@setplex/tria-api'
import { produce } from 'immer'
import type { LiveChannelFormatted } from './index.h'

export const produceFormattedChannel = (
  channel: LiveChannel
): LiveChannelFormatted =>
  produce(channel, (draft: LiveChannelFormatted) => {
    draft.logo = formatChannelLogoUrl(draft.logo || '')
    draft.banner = formatBannerUrl(draft.banner || '')

    let link = ''
    if (draft.id) {
      link = `${PATH.LIVE}/${formatTitleForUrl(draft.title)}${draft.id}${
        PATH.PLAY
      }`
    }
    draft['link'] = link
  }) as LiveChannelFormatted
