import type { API, GenericApiResponse } from '@setplex/tria-api'
import type { HttpClient } from '../../http'
import type { AdapterDefaults } from '../../index.h'
import { query } from '../../tools'
import { format as formatContent } from '../content/format'
import type { ApiContent } from '../content/index.h'
import { format } from './format'
import type { ApiOVPBannerCarousel } from './index.h'

export function use(
  http: HttpClient,
  carousels: API['carousels'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  // GET /api/web/carousels
  carousels.base.getManyFx.use(async ({ order }) => {
    const params = query({ sortOrder: order })
    const json = await http.get<GenericApiResponse<ApiOVPBannerCarousel[]>>(
      `/carousels${params}`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in carousels.base.getManyFx')
    }

    return (json.payload || []).map(format)
  })

  // GET /api/web/carousels/{id}
  carousels.base.getOneFx.use(async ({ id }) => {
    const json = await http.get<GenericApiResponse<ApiOVPBannerCarousel>>(
      `/carousels/${id}`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in carousels.base.getOneFx')
    }

    return format(json.payload)
  })

  // GET /api/web/carousels/{id}/content
  carousels.base.getContentFx.use(async ({ id, limit, offset }) => {
    const params = query({ limit, offset })
    const json = await http.get<GenericApiResponse<{ results: ApiContent[] }>>(
      `/carousels/${id}/content${params}`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in carousels.base.getContentFx')
    }

    return (json.payload.results || []).map(formatContent)
  })
}
