import { version as arcanaVersion } from '@setplex/arcane'
import { version as triaApiVersion } from '@setplex/tria-api'
import { version as ovpAdapterVersion } from '@setplex/tria-api-adapter-ovp'

// helper to check built-in versions
const orBuiltin = (version: string): string =>
  version === '__VERSION__' ? '#built-in' : version

export const appVersion = __APP_VERSION__
export const formattedAppVersion = `v.${appVersion}`

export const versions = {
  uvo: `${appVersion}#${__VCS_COMMITHASH__}`,
  arcana: orBuiltin(arcanaVersion),
  api: orBuiltin(triaApiVersion),
  adapter: orBuiltin(ovpAdapterVersion),
} as const
