import type { API } from '@setplex/tria-api'
import type { HttpClient } from '../../http'

export function use(
  http: HttpClient,
  support: API['support'],
  _api: API
): void {
  // POST /api/web/feedback/support
  support.setContactDataFx.use(async (params) => {
    const json = await http.post('/feedback/support', {
      body: params,
    })

    if (!json) {
      throw new Error('Empty answer in support.setContactDataFx')
    }
  })
}
