import { type Store as EffectorStore } from 'effector'

export enum MediaOrderType {
  FIRST = 'FIRST',
  DEFAULT = 'DEFAULT',
  LAST = 'LAST',
}

export enum MediaResolution {
  HD = 'HD',
  SD = 'SD',
  FHD = 'FHD',
  _2K = '_2K',
  _4K = '_4K',
}

export interface GenericApiResponse<T> {
  status: 'OK' | 'ERROR'
  errorCode: string
  message: string
  payload: T
}

/**
 * Nora WBS common API response format
 * @see https://setplexapps.atlassian.net/wiki/spaces/MW/pages/28114945/APIs+Response+Format
 */
export interface ApiCommonResponse {
  status?: 'OK' | 'ERROR'
  errorCode?: string
  message?: string
  payload?: any
}

enum Providers {
  EZDRM = 'EZDRM',
  PALLYCON = 'PALLYCON',
  EXPRESS_PLAY = 'EXPRESS_PLAY',
}

interface LaData {
  licenseUrl: string
  headers?: Record<string, string>
  fpsCertificateUrl?: string
  provider?: Providers
  iv?: string
  contentId?: string
}

export interface MediaUrl {
  playbackUrl: string
  drm?: {
    la?: Record<string, LaData>
  }
  stoppedTime?: number
}

export interface DownloadUrl {
  downloadFilename?: string
  downloadUrl?: string
}

export enum VideoType {
  VOD = 'VOD',
  VOD_TRAILER = 'VOD_TRAILER',
  SHOW = 'SHOW',
  SHOW_TRAILER = 'SHOW_TRAILER',
  CHANNEL = 'CHANNEL',
}

export enum MediaTypes {
  LIVE_CHANNEL = 'LIVE_CHANNEL',
  VIDEO = 'VIDEO',
  TV_SHOW = 'TV_SHOW',
}

export enum GlobalSearchTypes {
  LiveEvents = 'liveEvents',
  TvChannels = 'tvChannels',
  TvShows = 'tvShows',
  Vods = 'vods',
  RadioChannels = 'radioChannels',
}

export enum ResourceTypes {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  WITHOUT_LINK = 'WITHOUT_LINK',
}

export type WrappedData<T> = { content: T }

export type ContentStore<T> = EffectorStore<WrappedData<T>>
