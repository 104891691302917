import { model as router } from '!/router'
import { ERROR, PATH } from '@/constants'
import { F, T, nay } from '@/helpers'
import {
  birthDate,
  isDatePassed,
  isDateTooOld,
  maxLength,
  required,
} from '@/validators'
import { api } from '@setplex/tria-api'
import {
  attach,
  createEvent,
  createStore,
  sample,
  type EventCallable,
  type StoreWritable,
} from 'effector'
import { createForm } from 'effector-forms'
import { preferencesEntity } from '~/entities/preferences'
import { model as session } from '~/entities/session'
import { preferences } from '~/features/preferences'
import { profile } from '~/features/profile'
import { $route } from '~/processes/navigation'

export const genders = ['male', 'female', 'other']

// Welcome popup display logics
export const openWelcomePopup: EventCallable<void> = createEvent()
export const closeWelcomePopup: EventCallable<void> = createEvent()

export const $isWelcomePopupOpened: StoreWritable<boolean> =
  createStore<boolean>(false) //
    .on(openWelcomePopup, T)
    .on(closeWelcomePopup, F)

sample({
  clock: preferencesEntity.getExtraFx.pending,
  filter: nay,
  fn: F,
  target: $isWelcomePopupOpened,
})

// Show the welcome form until the user has filled out the firstName field
sample({
  clock: [$route, session.$user],
  filter: session.$needFinishRegistration,
  target: openWelcomePopup,
})

// We cannot open Welcome popup if need finish registration at Play page,
// so navigate to Home page and then Welcome popup will be opened
sample({
  source: {
    needFinishRegistration: session.$needFinishRegistration, // wait request resolving
    route: $route,
  },
  filter: ({ needFinishRegistration, route }) =>
    needFinishRegistration && route?.routeName === `${PATH.MOVIE}${PATH.PLAY}`,
  fn: () => PATH.HOME,
  target: router.navigatePush,
})

// After user (logged by password) finish registration, close Welcome popup and navigate to Home page
sample({
  clock: session.$needFinishRegistration,
  source: {
    isFirstLogin: session.$isFirstLogin,
    isFirstLoginBySocialNetwork: session.$isFirstLoginBySocialNetwork,
  },
  filter: (
    { isFirstLogin, isFirstLoginBySocialNetwork },
    needFinishRegistration
  ) => !isFirstLogin && !isFirstLoginBySocialNetwork && !needFinishRegistration, // there is another flow if first login
  fn: () => PATH.HOME,
  target: [closeWelcomePopup, router.navigatePush],
})

export const welcomeForm = createForm({
  fields: {
    firstName: {
      init: '' as string,
      rules: [required(), maxLength(80)],
    },
    lastName: {
      init: '' as string,
      rules: [maxLength(80)],
    },
    birthday: {
      init: '' as string,
      rules: [birthDate(), isDatePassed(), isDateTooOld()],
    },
    gender: {
      init: '',
    },
  },
  validateOn: ['submit'],
})

// get profile
const getProfileFx = api.profile.getProfileFx

sample({
  clock: openWelcomePopup,
  target: getProfileFx,
})

sample({
  clock: getProfileFx.doneData,
  fn: ({ firstName, lastName, birthday, gender }) => ({
    firstName: firstName || '',
    lastName: lastName || '',
    birthday: birthday || '',
    gender: gender || '',
  }),
  target: welcomeForm.setForm,
})

// set profile
export const setProfileFx = attach({ effect: api.profile.setProfileFx })

sample({
  clock: welcomeForm.formValidated,
  fn: ({ firstName, lastName, birthday, gender }) => ({
    firstName: firstName.trim() || '',
    lastName: lastName.trim() || null,
    birthday,
    gender,
  }),
  target: setProfileFx,
})

// Update profile
sample({
  clock: setProfileFx.done,
  target: [api.userSession.checkStatusFx, profile.getProfileFx],
})

// Open preferences popup after user has successfully update profile at welcome form
sample({
  clock: setProfileFx.done,
  filter: session.$isFirstLogin,
  target: [preferences.setTryOpeningPopupToTrue, preferencesEntity.getExtraFx],
})

sample({
  clock: setProfileFx.done,
  filter: session.$isFirstLoginBySocialNetwork,
  target: [preferences.setTryOpeningPopupToTrue, preferencesEntity.getExtraFx],
})

export const resetErrorOnClick: EventCallable<void> = createEvent()

// error to show to the user
export const $error: StoreWritable<string | null> = createStore<string | null>(
  null
)
  .on(setProfileFx.failData, () => ERROR.SOMETHING_WENT_WRONG)
  .reset($isWelcomePopupOpened, resetErrorOnClick)
