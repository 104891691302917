import { AuthPopupFixed, ErrorMessage } from '@setplex/arcane'
import { useUnit } from 'effector-react'
import { AuthFormWrapper } from '~/routes/auth/shared/ui'
import { WelcomeForm } from './components/WelcomeForm'
import { $error, $isWelcomePopupOpened, resetErrorOnClick } from './model'

WelcomePopup.displayName = 'WelcomePopup'
export function WelcomePopup() {
  const error = useUnit($error)
  const isWelcomePopupOpened = useUnit($isWelcomePopupOpened)

  return (
    <AuthPopupFixed
      wrapperId='welcome-popup'
      className='auth-popup-container-sign-up'
      isOpened={isWelcomePopupOpened}
    >
      <AuthFormWrapper className='auth-form-wrapper-welcome'>
        <WelcomeForm />
      </AuthFormWrapper>
      {error && (
        <ErrorMessage
          width='100%'
          title={error}
          maxLines={0}
          resetError={resetErrorOnClick}
        />
      )}
    </AuthPopupFixed>
  )
}
