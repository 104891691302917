import { createEffect } from 'effector'
import { type HeaderFxParameter } from '../../index.h'
import { http, isOk, type ApiResponse } from '../http'
import { ModulesTypesEnum, type Modules } from './index.h'

export { ModulesTypesEnum, type Modules }

// GET /api/web/modules
export const getModulesFx = createEffect<HeaderFxParameter, Modules, Error>(
  async (params) => {
    const { headers = {} } = params ?? {}

    const json: ApiResponse<Modules> = await http
      .get('modules', {
        headers,
      })
      .json()

    if (isOk(json)) {
      return json.payload || []
    } else {
      throw new Error('failed to load modules')
    }
  }
)
