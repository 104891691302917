import { createEffect, type Effect } from 'effector'
import type { GenericApiResponse } from '../../interfaces/generic'
import type { UsersPreferences } from './index.h'

export type { UsersPreferences }

export const getOnboardingExtraFx: Effect<
  void,
  UsersPreferences,
  GenericApiResponse<Error>
> = createEffect()
