import { type RequestInput } from '../index.h'
import { type Effect, createEffect, sample } from 'effector'
import { RequestError, RequestHttpError, RequestFailedError } from './errors'
import { http } from '../events'

export const fx: Effect<RequestInput, Response, RequestError> = createEffect<
  RequestInput,
  Response,
  RequestError
>()

sample({
  clock: fx,
  fn: (input) => ({ input }),
  target: http.request,
})

sample({
  clock: fx.done,
  fn: ({ params, result }) => ({ input: params, response: result }),
  target: http.response,
})

sample({
  clock: fx.fail,
  filter: ({ error }) => error instanceof RequestHttpError,
  fn: ({ params, error }) => ({
    input: params,
    error,
    request: (error as any).request,
    response: (error as any).response,
  }),
  target: http.response,
})

sample({
  clock: fx.fail,
  filter: ({ error }) => error instanceof RequestFailedError,
  fn: ({ params, error }) => ({
    input: params,
    error,
  }),
  target: http.error,
})
