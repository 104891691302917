import { A } from '@mobily/ts-belt'
import { api } from '@setplex/tria-api'
import { attach, createEvent, sample } from 'effector'
import { NO_EPG_ID, type EpgRequestParams } from '../index.h'
import { isValidRange } from '../lib/ranges'

// event to guard calling actual fetch, in case of bad params
export const fetchEpgInfo = createEvent<EpgRequestParams>()

// actual fetch epg data effect
export const fetchEpgInfoFx = attach({
  effect: api.liveChannel.base.getEpgInformationFormattedFx,
})

// fetch epg data for channels for biggest calculated range
sample({
  clock: fetchEpgInfo,
  filter: ({ channels, period }) => channels.length > 0 && isValidRange(period),
  fn: ({ channels, period }) => {
    return {
      epgIds: A.uniq(
        A.map(channels, ({ channelEpgId }) => channelEpgId ?? NO_EPG_ID)
      ) as string[],
      channels,
      // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
      period: { start: period![0], end: period![1] },
    }
  },
  target: fetchEpgInfoFx,
})
