/*
 * Different useful number helpers
 */

/**
 * Return random integer in range [min; max] (by default) or [min; max)
 */
export const random = (min: number, max: number, inclusive = true): number => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + Number(inclusive)) + min)
}
/**
 * Return increment
 */
export const inc = (arg: number): number => arg + 1

/**
 * Return decrement
 */
export const dec = (arg: number): number => arg - 1
