import { features } from '!/flags'
import {
  AdditionalLinkContainer,
  AuthButtonGroupTemplate,
  ErrorMessage,
  ForgotPasswordLink,
  InlineButtonAdditional2,
  LoginPasswordInputBlock,
  LoginUsernameInputBlock,
  PrimarySubmitButton,
  RememberCheckBox,
  SignInActionContainer,
  SignInFormTemplate,
  SignUpInputContainerSC,
  SocialAppleButtonTemplate,
  SocialFacebookButtonTemplate,
  SocialGoogleButtonTemplate,
  TextBlockBody12RWithInlineButton,
  TextBoxTitle16BOnSurface2,
  TextBoxTitle24BOnSurface1,
  TitleLineDivider,
} from '@setplex/arcane'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'
import { type ChangeEvent, type FormEvent } from 'react'
import { PATH } from '~/shared/constants/path'
import { addGoogleAnalyticsPrefix } from '~/shared/helpers'
import * as signUpModel from '~/widgets/auth/sign-up/model'
import { $error, resetErrorOnClick, signInForm, signInFx } from '../model'

SignInForm.displayName = 'SignInForm'
export function SignInForm() {
  const pending = useUnit(signInFx.pending)
  const error = useUnit($error)
  const { submit, fields, eachValid, hasError, errorText } = useForm(signInForm)

  const facebookEnabled = useUnit(features.$facebookEnabled)
  const googleEnabled = useUnit(features.$googleEnabled)
  const appleEnabled = useUnit(features.$appleEnabled)

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  const onOpenSignUpPopup = () => signUpModel.openSignUpPopup()

  return (
    <SignInFormTemplate
      noValidate
      onSubmit={onSubmit}
      onClick={resetErrorOnClick}
    >
      <TextBoxTitle24BOnSurface1 title='Sign In' className='signin-title' />

      <TextBoxTitle16BOnSurface2
        title="It's FREE No Subscription Required."
        className='signin-subtitle'
      />

      <AuthButtonGroupTemplate>
        {facebookEnabled && (
          <a
            href='/api/web/auth/facebook'
            data-ignore-router
            className={addGoogleAnalyticsPrefix('sign-in-facebook')}
          >
            <SocialFacebookButtonTemplate
              classNameGA={addGoogleAnalyticsPrefix('sign-in-facebook')}
            />
          </a>
        )}
        {appleEnabled && (
          <a
            href='/api/web/auth/apple'
            data-ignore-router
            className={addGoogleAnalyticsPrefix('sign-in-apple')}
          >
            <SocialAppleButtonTemplate
              classNameGA={addGoogleAnalyticsPrefix('sign-in-apple')}
            />
          </a>
        )}
        {googleEnabled && (
          <a
            href='/api/web/auth/google'
            data-ignore-router
            className={addGoogleAnalyticsPrefix('sign-in-google')}
          >
            <SocialGoogleButtonTemplate
              classNameGA={addGoogleAnalyticsPrefix('sign-in-google')}
            />
          </a>
        )}
      </AuthButtonGroupTemplate>

      <TitleLineDivider title='or' />

      <SignUpInputContainerSC>
        <LoginUsernameInputBlock
          name='email'
          value={fields.email.value}
          placeholder='Email'
          label='Email'
          autoComplete='username'
          disabled={pending}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            fields.email.onChange(e.target.value)
          }
          errorMessage={
            hasError('email')
              ? errorText('email', {
                  required: 'Please, enter Email',
                  email: 'Please, enter a valid Email',
                })
              : ''
          }
        />

        <LoginPasswordInputBlock
          name='password'
          value={fields.password.value}
          placeholder='Password'
          label='Password'
          autoComplete='current-password'
          disabled={pending}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            fields.password.onChange(e.target.value)
          }
          errorMessage={
            hasError('password')
              ? errorText('password', {
                  required: 'Please, enter Password',
                  password:
                    'Please, enter a valid Password. Only letters (aA-zZ), numbers (0-9) and symbols (@#$_&-*!?.) are allowed',
                  maxLength:
                    'Password is too long, maximum length is 64 characters',
                })
              : ''
          }
        />
      </SignUpInputContainerSC>

      <AdditionalLinkContainer>
        <RememberCheckBox
          id='rememberMe'
          name='rememberMe'
          onChange={(e) => fields.rememberMe.onChange(e.target.checked)}
        />
        <ForgotPasswordLink>
          <a href={PATH.FORGOT_PASSWORD}>
            <InlineButtonAdditional2 title='Forgot password?' />
          </a>
        </ForgotPasswordLink>
      </AdditionalLinkContainer>

      <PrimarySubmitButton
        classNameGA={addGoogleAnalyticsPrefix('sign-in-form')}
        title='Sign In'
        disabled={pending || !eachValid || Boolean(error)}
        isLoading={pending}
      />

      <SignInActionContainer>
        <TextBlockBody12RWithInlineButton title="Don't have an account?">
          <InlineButtonAdditional2
            title='Register Free'
            onClick={onOpenSignUpPopup}
          />
        </TextBlockBody12RWithInlineButton>
      </SignInActionContainer>

      {error && (
        <ErrorMessage
          width='100%'
          position='fixed'
          title={error}
          maxLines={0}
          resetError={resetErrorOnClick}
        />
      )}
    </SignInFormTemplate>
  )
}
