import type { API, GenericApiResponse } from '@setplex/tria-api'
import type { HttpClient } from '../../http'
import type { AdapterDefaults } from '../../index.h'
import { query } from '../../tools'
import { format, formatSuggestion } from './format'
import type { ApiSearch, ApiSearchSuggestion } from './index.h'

export function use(
  http: HttpClient,
  search: API['search'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  // *
  // * base API effects
  // *

  // GET /api/web/search
  search.base.getFx.use(async ({ q, limit }) => {
    const params = query({ q, limit })
    const json = await http.get<GenericApiResponse<ApiSearch>>(
      `/search${params}`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in search.base.getFx')
    }

    return format(json.payload)
  })

  // GET /api/web/search/suggestion
  search.base.getSuggestionsFx.use(async ({ q, limit }) => {
    const params = query({ q, limit })
    const json = await http.get<GenericApiResponse<ApiSearchSuggestion>>(
      `/search/suggestion${params}`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in search.base.getSuggestionsFx')
    }

    return formatSuggestion(json.payload)
  })
}
