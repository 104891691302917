import { T } from '@/helpers'
import {
  createEvent,
  createStore,
  sample,
  type EventCallable,
  type Store,
  type StoreWritable,
} from 'effector'
import { getAll } from 'firebase/remote-config'
import { and, delay, not } from 'patronum'
import { filter, fromPairs, map, pipe, toPairs } from 'rambda'
import { firebaseSharedModel as firebase } from '~/shared/firebase'
import {
  asType,
  byKey,
  defaults,
  type Remote,
  type RemoteKeys,
} from './defaults'
import { MAX_REMOTE_CONFIG_AWAIT_TIME } from './index.h'

export const setReady: EventCallable<void> = createEvent()
export const $ready: StoreWritable<boolean> = createStore(false).on(setReady, T)

// firebase remote config store
export const $config = createStore<Remote>(defaults)

// firebase remote config single key getter
export const get = <K extends RemoteKeys>(
  name: K,
  def: NonNullable<Remote[K]> = defaults[name]
): Store<NonNullable<Remote[K]>> => $config.map((remote) => remote[name] ?? def)

// set firebase remote config defaults
sample({
  clock: firebase.$ready,
  filter: Boolean,
  fn: () => defaults,
  target: firebase.remote.setDefaults,
})

// fetch and activate on firebase readiness
// and re-fetch and re-activate remote config on any user property change
sample({
  clock: firebase.$ready, //
  filter: and(firebase.$ready, not(firebase.remote.fetchAndActivateFx.pending)),
  target: firebase.remote.fetchAndActivateFx,
})

// convert firebase remote config to local config
sample({
  clock: firebase.remote.fetchAndActivateFx.done,
  source: firebase.remote.$firebaseConfig,
  filter: Boolean,
  // it is hard to properly type this pipe, TypeScript is not happy with function composition...
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: (pipe as any)(
    getAll,
    filter(byKey),
    toPairs,
    map(([key, value]) => [key, asType(key, value)]),
    fromPairs
  ),
  target: $config,
})

// set ready after remote config is loaded or failed to load
sample({
  clock: firebase.remote.fetchAndActivateFx.finally,
  target: setReady,
})

// set ready after some timeout, if remote config is not loaded
delay({
  source: firebase.remote.fetchAndActivateFx,
  timeout: MAX_REMOTE_CONFIG_AWAIT_TIME,
  target: setReady,
})
