/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { noop } from '@/helpers'
import { debug as _debug } from 'patronum/debug'
import { env } from '~/app/environment'
import { logger } from './shared/logger'

declare global {
  var debug: typeof _debug
  var debugUseEvents: boolean
  var debugUseData: boolean
  var debugUseRaw: boolean
  var debugExact: boolean
}

if (process.env.NODE_ENV !== 'production') {
  globalThis.debug = _debug
  globalThis.debugUseEvents = false
  globalThis.debugUseData = false
  globalThis.debugUseRaw = false
  globalThis.debugExact = false
} else {
  globalThis.debug = noop as any
}

// update env variables
for (const prop of Object.keys(env)) {
  if (typeof prop === 'string' && prop.startsWith('UVO_CLIENT_')) {
    try {
      const x = localStorage.getItem(prop)
      if (x) {
        // eslint-disable-next-line no-extra-semi
        ;(env as any)[prop] = JSON.parse(x, (_key, value) =>
          value === 'Infinity' ? Infinity : value
        )
      }
    } catch (e) {
      logger.error(`failed to override variable "${prop}":`, e)
    }
  }
}

// global variable to override ENV values locally
// eslint-disable-next-line no-extra-semi
window.DEBUG = new Proxy(
  {
    ...env,
  },
  {
    set(_target, prop, val) {
      if (typeof prop === 'string' && prop.startsWith('UVO_CLIENT_')) {
        try {
          const x = JSON.stringify(val, (_key, value) =>
            value === Infinity ? 'Infinity' : value
          )
          localStorage.setItem(prop, x)
          logger.log(`set variable "${prop}" to '${val}', reload page`)
          return true
        } catch (e) {
          logger.error(`failed to set variable "${prop}":`, e)
          return false
        }
      } else {
        logger.error(`unknown variable "${String(prop)}", ignored`)
        return false
      }
    },

    deleteProperty(_target, prop) {
      if (typeof prop === 'string' && prop.startsWith('UVO_CLIENT_')) {
        try {
          localStorage.removeItem(prop)
          logger.log(`unset variable "${prop}", reload page`)
          return true
        } catch (e) {
          logger.error(`failed to unset variable "${prop}":`, e)
          return false
        }
      } else {
        logger.error(`unknown variable "${String(prop)}", ignored`)
        return false
      }
    },
  }
)
