import { MAX_YEAR } from '@/constants'
import { addSlashes } from '@/helpers'
import {
  DropdownDarkDefault,
  DropdownItemDarkDefault,
  GradientButtonWithCorners,
  InlineButtonLabel12ROnSurface2,
  LoginUserFirstNameInputBlock,
  LoginUserLastNameInputBlock,
  SignUpSecondStepForm,
  Text,
  TextBoxBody16ROnSurface2,
  TextBoxTitle16BOnSurface1,
  TextTag,
  UserAgeInputBlock,
  WelcomeInfoTextBlock,
} from '@setplex/arcane'
import { useForm } from 'effector-forms'
import { useUnit } from 'effector-react'
import { useState, type ChangeEvent, type FormEvent } from 'react'
import { preferencesEntity } from '~/entities/preferences'
import { $error, genders, setProfileFx, welcomeForm } from '../model'

WelcomeForm.displayName = 'WelcomeForm'
export function WelcomeForm() {
  const [info, showInfo] = useState(false)
  const pendingSetProfile = useUnit(setProfileFx.pending)
  const pendingGetExtra = useUnit(preferencesEntity.getExtraFx.pending)
  const pending = pendingSetProfile || pendingGetExtra
  const error = useUnit($error)
  const { submit, fields, eachValid, hasError, errorText } =
    useForm(welcomeForm)

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    submit()
  }

  return (
    <SignUpSecondStepForm onSubmit={onSubmit} noValidate>
      {info && (
        <WelcomeInfoTextBlock>
          <TextBoxTitle16BOnSurface1
            className='welcome-form-info-block-title'
            title='Why is UVOtv asking for my age?'
          />
          <TextBoxBody16ROnSurface2
            className='welcome-form-info-block-description'
            title="Asking for a user’s age is a method used by companies to determine whether a user meets certain age requirements, typically set out in the company's terms of use, in a way that's consistent with industry, legal, and regulatory guidelines and requirements. For example, it helps avoid showing content or advertisements not suitable for minors (e.g. like alcohol ads)."
          />

          <TextBoxTitle16BOnSurface1
            className='welcome-form-info-block-title'
            title='Why is UVOtv asking for my gender?'
          />
          <TextBoxBody16ROnSurface2
            className='welcome-form-info-block-description'
            title='This information, similar to above, is used by UVOtv to create customized experience for users.'
          />

          <GradientButtonWithCorners
            title='Go Back'
            onClick={() => showInfo(false)}
          />
        </WelcomeInfoTextBlock>
      )}

      {!info && (
        <>
          <Text
            className='text--fonts-title-24-b text--color-on-surface-1'
            tag={TextTag.H1}
            text="We're happy to have you with us!"
          />
          <Text
            className='form-title text--fonts-title-16-b text--color-on-surface-2'
            text='Help us be better. Tell us about yourself'
          />

          <LoginUserFirstNameInputBlock
            name='firstName'
            value={fields.firstName.value}
            label='First Name*'
            placeholder='First Name*'
            autoComplete='off'
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              fields.firstName.onChange(e.target.value)
            }
            errorMessage={
              hasError('firstName')
                ? errorText('firstName', {
                    required: 'Please, enter First Name',
                    maxLength:
                      'First Name must be shorter or equal to 80 characters',
                  })
                : ''
            }
          />
          <LoginUserLastNameInputBlock
            name='lastName'
            value={fields.lastName.value}
            label='Last Name'
            placeholder='Last Name'
            autoComplete='off'
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              fields.lastName.onChange(e.target.value)
            }
            errorMessage={
              hasError('lastName')
                ? errorText('lastName', {
                    maxLength:
                      'Last Name must be shorter or equal to 80 characters',
                  })
                : ''
            }
          />
          <div className='welcome-form-inputs-wrapper'>
            <UserAgeInputBlock
              autoComplete='off'
              name='birthDate'
              value={fields.birthday.value}
              label='Birth date'
              placeholder='Birth date'
              maxLength='10'
              disabled={pending}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                fields.birthday.onChange(addSlashes(e.target.value))
              }
              errorMessage={
                hasError('birthday')
                  ? errorText('birthday', {
                      birthDate:
                        'Please enter a valid date of birth in the format MM/DD/YYYY',
                      isDatePassed: 'The date must be in the past',
                      isDateTooOld: `Please enter a year after ${
                        new Date().getFullYear() - MAX_YEAR
                      }`,
                    })
                  : ''
              }
            />
            <DropdownDarkDefault
              title={fields.gender.value}
              placeholder='Select your gender'
            >
              {genders?.map((gender, index) => (
                <DropdownItemDarkDefault
                  key={`${index}${gender}`}
                  title={gender}
                  id={gender}
                  activeId={fields.gender.value}
                  onClick={() => welcomeForm.fields.gender.set(gender)}
                />
              ))}
            </DropdownDarkDefault>
          </div>
          <InlineButtonLabel12ROnSurface2
            className='info-link'
            title='Why is this info asked?'
            onClick={() => showInfo(true)}
          />
          <GradientButtonWithCorners
            type='submit'
            title='Next'
            disabled={pending || !eachValid || Boolean(error)}
            isLoading={pending}
          />
        </>
      )}
    </SignUpSecondStepForm>
  )
}
