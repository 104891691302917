import type { API, GenericApiResponse } from '@setplex/tria-api'
import type { HttpClient } from '../../http'
import type { ApiUsersPreferences } from './index.h'

export type { ApiUsersPreferences }

export function use(
  http: HttpClient,
  onboarding: API['onboarding'],
  _api: API
): void {
  // GET /api/web/onboarding/extra
  onboarding.getOnboardingExtraFx.use(async () => {
    const json = await http.get<GenericApiResponse<ApiUsersPreferences>>(
      `/onboarding/extra`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in onboarding.getOnboardingExtraFx')
    }

    return json.payload
  })
}
