import type { API, GenericApiResponse } from '@setplex/tria-api'
import type { HttpClient } from '../../http'
import { format, formatParams } from './format'
import type { ApiOVPProfile } from './index.h'

export function use(
  http: HttpClient,
  profile: API['profile'],
  _api: API
): void {
  // GET /api/web/users/profile
  profile.getProfileFx.use(async () => {
    const json = await http.get<GenericApiResponse<ApiOVPProfile>>(
      `/users/profile`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in user.getProfileFx')
    }

    return format(json.payload)
  })

  // POST /api/web/users/profile
  profile.setProfileFx.use(async (params) => {
    const body = formatParams(params)
    const json = await http.post('/users/profile', {
      body: JSON.stringify(body),
    })

    if (!json) {
      throw new Error('Empty answer in user.setProfileFx')
    }
  })
}
