import { createEvent, createStore } from 'effector'
import { EpgDateEx } from '~/entities/epg/dates'

// store for setting active Day
export const resetActive = createEvent()
export const setActive = createEvent<EpgDateEx | null>()
export const $active = createStore<EpgDateEx | null>(null)
  .on(setActive, (current, date) => {
    return current == null || date != null ? date : current
  })
  .reset(resetActive)
