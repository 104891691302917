export enum HomeRowType {
  RECOMMENDED = 'recommended',
  FAVORITES = 'myList',
  CAROUSEL = 'carousel',
  COLLECTION = 'collection',
  PRIMARY_LANGUAGE = 'primaryLanguageContent',
  LIVE = 'live',
  CONTENT = 'content',
  TAG = 'tag',
  USER = 'user',
}

export interface HomeRowFilters {
  creatorId: number
}

export interface HomeRowSorting {
  sortBy?: string
  sortOrder?: string
}

export interface HomeRow {
  id: number
  rowEntityType: HomeRowType
  rowEntityId?: number
  sortOrder: number
  title: string
  contentLimit?: number
  filters?: HomeRowFilters
  order?: HomeRowSorting
}

export interface HomeRows {
  carouselId: number
  rows: HomeRow[]
}
