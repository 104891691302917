import {
  OVPBannerType,
  type Collection,
  type Content,
  type OVPBannerCarousel,
  type OVPBannerContent,
  type Tag,
} from '@setplex/tria-api'
import { format as formatCollection } from '../collections/format'
import { type ApiCollection } from '../collections/index.h'
import { format as formatContent } from '../content/format'
import { type ApiContent } from '../content/index.h'
import { format as formatTag } from '../tags/format'
import { type ApiTag } from '../tags/index.h'
import { type ApiOVPBannerCarousel, type ApiOVPBannerContent } from './index.h'

export const formatBanner = ({
  type,
  sortOrder,
  ...item
}: ApiOVPBannerContent): OVPBannerContent => ({
  type,
  sortOrder,
  ...formatCarouselItemsDependOfType(item, type),
})

const formatCarouselItemsDependOfType = (
  item: ApiContent | ApiTag | ApiCollection,
  type: OVPBannerType
): Content | Tag | Collection => {
  switch (type) {
    case OVPBannerType.CONTENT:
      return formatContent(item as ApiContent)

    case OVPBannerType.TAGS:
      return formatTag(item as ApiTag)

    case OVPBannerType.COLLECTIONS:
      return formatCollection(item as ApiCollection)

    default:
      return item as any
  }
}

export const format = ({
  id,
  title,
  autoScroll,
  autoScrollInterval,
  items,
}: ApiOVPBannerCarousel): OVPBannerCarousel => ({
  id,
  title,
  autoScroll,
  autoScrollInterval,
  items: items && items.length ? items.map(formatBanner) : (items as any),
})
