import { model as session } from '$/session'
import { aye } from '@/helpers'
import { api, type OVPProfile } from '@setplex/tria-api'
import { attach, createEvent, createStore, sample } from 'effector'

export const init = createEvent()

export const getProfileFx = attach({ effect: api.profile.getProfileFx })

// Get profile after launch to update primaryLanguage
sample({
  clock: session.$isAuthenticated,
  filter: aye,
  target: getProfileFx,
})

export const $profile = createStore<OVPProfile | null>(null) //
  .reset(
    getProfileFx.fail,
    api.user.logOutFx.done,
    api.events.http.unauthorized
  )
  .on(getProfileFx.doneData, (_, profile) => profile)
