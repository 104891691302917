import { addDays, subDays } from 'date-fns'
import { formatDateShort } from '~/shared/helpers'
import { DEFAULT_WEEK_DURATION, type EpgDate, type EpgDateEx } from './index.h'

/**
 * Convert EpgDate to timestamp in ms
 */
export const toTimestamp = (x: EpgDate): number =>
  new Date(x.year, x.month, x.day).getTime()

/**
 * Compare equality of two epg dates
 */
export const isSameDate = (a: EpgDate | Date) => (b: EpgDate | Date) => {
  const x = a instanceof Date ? fromDate(a) : a
  const y = b instanceof Date ? fromDate(b) : b
  return x.year === y.year && x.month === y.month && x.day === y.day
}

/**
 * Clone Date object
 */
export const fromDate = (date: Date): EpgDate => {
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  return { year, month, day }
}

/**
 * Clone other EpgDate with actualization `isToday`, `isYesterday`, and `isTomorrow` flags
 */
export const fromEpgDate = (x: EpgDate): EpgDateEx => {
  const { year, month, day } = x

  const today = new Date()
  const yesterday = subDays(today, 1)
  const tomorrow = addDays(today, 1)

  return {
    year,
    month,
    day,
    isToday: isSameDate(today)(x),
    isYesterday: isSameDate(yesterday)(x),
    isTomorrow: isSameDate(tomorrow)(x),
  }
}

/**
 * Get current date
 */
export const getToday = (): EpgDateEx => {
  return fromEpgDate(fromDate(new Date()))
}

/**
 * Generate week in future from the given date + 1 day before today, yesterday
 */
export const generateWeek = (
  from: EpgDateEx,
  duration = DEFAULT_WEEK_DURATION
): EpgDateEx[] => {
  const week = [from]
  const date = new Date(from.year, from.month, from.day)

  // starts from 1 because we already have the first day
  for (let i = 1; i < duration; i++) {
    date.setDate(date.getDate() + 1)
    week.push(fromEpgDate(fromDate(date)))
  }

  // update with yesterday
  date.setDate(date.getDate() - DEFAULT_WEEK_DURATION)
  week.unshift(fromEpgDate(fromDate(date)))

  return week
}

/**
 * Return formatted title day in format 'Today, 11 Aug', '26 Aug'
 */
export const transformDate = (date: EpgDateEx) => {
  const yesterday = 'Yesterday'
  const today = 'Today'
  const tomorrow = 'Tomorrow'
  const title = formatDateShort(new Date(date.year, date.month, date.day))
  if (date.isYesterday) {
    date.title = `${yesterday}, ${title}`
  } else if (date.isToday) {
    date.title = `${today}, ${title}`
  } else if (date.isTomorrow) {
    date.title = `${tomorrow}, ${title}`
  } else {
    date.title = title
  }

  return date
}
