/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Features } from '!/flags'
import { isFunction, isPromise } from '@/helpers'
import { type OVPUserSessionData } from '@setplex/tria-api'
import { type Effect, type Unit } from 'effector'
import { type ComponentClass, type FunctionComponent } from 'react'
import UniversalRouter, {
  type Route,
  type RouteContext,
  type RouteParams,
  type RouterContext,
} from 'universal-router'
import generateUrls from 'universal-router/generateUrls'

// re-export from 'universal-router' to lessen dependency, just in case
export { type Route }

export type RouterResponse = {
  /* If filter fn returns false - screen 404 will be shown */
  filter?: (ctx: {
    features: Features
    session: OVPUserSessionData
  }) => boolean | undefined
  title?: string
  routeName?: string
  component?:
    | FunctionComponent<any>
    | ComponentClass<any>
    | Array<FunctionComponent<any> | ComponentClass<any>>
  props?: any
  // error?: Error
  wait?: Effect<any, any>
  init?: Unit<any>
  left?: Unit<any>
  redirect?: string
  seoIndexing?: boolean
}

type RouterResponseEx = RouterResponse | undefined | null

// top-level routes object
const routes: Route = {}

// router instance
export const router: UniversalRouter<RouterResponse> =
  new UniversalRouter<RouterResponse>(routes, { resolveRoute })

// url generating function
export const url = generateUrls(router)

/**
 * Re-Instantiate router with new routes
 */
export function init(appRoutes: Route) {
  Object.assign(routes, impregnate(appRoutes))
}

/**
 * Overridden resolve route for universal router,
 * handles dynamic routes and other custom properties
 */
function resolveRoute(
  context: RouteContext<RouterResponse, RouterContext>,
  params: RouteParams
): RouterResponseEx | Promise<RouterResponseEx> {
  const { route, features, session } = context

  if (route.load) {
    return resolveAsyncRoute(context, params)
  }

  if (isFunction(route.action)) {
    const result: RouterResponseEx | Promise<RouterResponseEx> = //
      route.action(context, params)

    return isPromise(result)
      ? Promise.resolve(result).then((result) => merge(context, result))
      : merge(context, result)
  }

  if (isFunction(route.filter)) {
    const result: boolean | undefined = route.filter({ features, session })
    if (result === false) {
      return null // `null` means skip this and all nested routes, and go to the next sibling route
    }
  }

  if (route.component) {
    return merge(context, {})
  }

  return undefined // undefined means router will try to match the child routes
}

/**
 * Asynchronously resolves route for universal router,
 * recursively calls `resolveRoute` after async load
 */
async function resolveAsyncRoute(
  context: RouteContext<RouterResponse, RouterContext>,
  params: RouteParams
): Promise<RouterResponseEx> {
  const { route } = context

  const load: Promise<{ default: Route }> | undefined = //
    isFunction(route.load) //
      ? route.load()
      : route.load

  // resolve async loader
  const page: { default: Route } | undefined = await Promise.resolve(load)

  // extends current context route
  if (page && page.default) {
    const def: Route = page.default
    delete route.load // load only once
    delete route.children // orphan all children, so universal router could do some optimisations
    Object.assign(route, impregnate(def)) // but route could contain more loaders
  }

  return resolveRoute(context, params)
}

/**
 * Adds empty children array to route in case it contains load function
 */
function impregnate(route: Route): Route {
  // has loader and has no children -> add empty children array
  if (route.load && !route.children) {
    route.children = []
  }

  // has children -> recursively impregnate each child
  else if (route.children && route.children.length) {
    for (const child of route.children) {
      impregnate(child)
    }
  }

  return route
}

/**
 * Merges route with `action` function result
 */
function merge(
  context: RouterContext,
  result?: RouterResponseEx
): RouterResponseEx {
  if (result != null) {
    // autogenerate breadcrumbs-like document.title
    // NB! will be overridden in case `action` returns own title
    const titles = []
    let route = context.route
    while (route) {
      if (route.title) {
        titles.unshift(route.title)
      }
      route = route.parent
    }

    // return merged route result
    return {
      title: titles.join(' » '),
      routeName: context.route.routeName,
      component: context.route.component,
      wait: context.route.wait,
      init: context.route.init,
      left: context.route.left,
      seoIndexing: context.route.seoIndexing,
      ...result,
      props: {
        query: context.query as URLSearchParams,
        ...context.params,
        ...result.props,
      },
    }
  }

  return result // null or undefined
}
