import { type To } from './index.h'
import * as model from './model'

export * as dom from './dom'
export * from './index.h'
export * from './router'
export * as scroll from './scroll'
export * from './stage'
export { model }

/**
 * Helper for navigation
 */
export const navigate = (to: To): To => model.navigatePush(to)
navigate.push = model.navigatePush
navigate.replace = model.navigateReplace
navigate.go = model.navigateGo
navigate.back = model.navigateBack
navigate.forward = model.navigateForward

// TODO: remove
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
;(window as any).navigate = navigate
