import {
  attach,
  createEvent,
  createStore,
  sample,
  type Effect,
  type EventCallable,
  type StoreWritable,
} from 'effector'
import { defined } from './utility'

const empty: unique symbol = Symbol()

export const stateful = <
  Params,
  Done,
  Fail,
  M extends {
    [method: string]: (arg: any, params: Params, data: Done | null) => Params
  }
>({
  effect,
  initial = empty as any as Params,
  methods = {} as M,
}: {
  effect: Effect<Params, Done, Fail>
  initial?: Params
  methods?: M
}): {
  data: StoreWritable<Done | null>
  done: StoreWritable<{ params: Params; result: Done } | null>
  get: Effect<Params, Done, Fail>
  adjust: EventCallable<Params>
} & {
  [K in keyof M]: M[K] extends (
    arg: infer A,
    params: Params,
    data: Done | null
  ) => Params
    ? EventCallable<A>
    : EventCallable<unknown>
} => {
  const adjust: EventCallable<Params> = createEvent()
  const $params: StoreWritable<Params> = createStore<Params>(initial).on(
    adjust,
    (current, adjusted) => defined({ ...current, ...adjusted }) as Params
  )

  const $data: StoreWritable<Done | null> = createStore<Done | null>(null)
  const $done: StoreWritable<{ params: Params; result: Done } | null> =
    createStore<{
      params: Params
      result: Done
    } | null>(null)

  // create new attacjed effect, so we will not react on original one
  const attached: Effect<Params, Done, Fail> = attach({ effect })

  // create new attached effect to merge previously preserved params with new ones
  const fx: Effect<Params, Done, Fail> = attach({
    source: $params,
    mapParams: (current, previous) =>
      (previous as any) === empty || // first call -> use current params
      current === null || // current params equals to `null` -> use current params (erase previous)
      typeof current !== 'object' // current params is primitive value -> use current params (cannot merge)
        ? current
        : // otherwise merge giben params with previous
          (defined({ ...previous, ...current }) as Params),
    effect: attached,
  })

  // memorise first call params, only on initial call
  sample({
    clock: fx,
    source: $params,
    filter: (params) => (params as any) === empty,
    fn: (_, params) => params,
    target: $params,
  })

  // memorize new params for later calls
  sample({
    clock: attached,
    target: $params,
  })

  // memorize effect result data for later use in methods
  sample({
    clock: attached.doneData,
    target: $data,
  })

  // memorize successful effect result (params and data)
  sample({
    clock: attached.done,
    target: $done,
  })

  // result object
  const result: any = {
    data: $data,
    done: $done,
    get: fx,
    adjust,
  }

  // extend result object with events-modifiers
  for (const [method, fn] of Object.entries(methods)) {
    const event: EventCallable<any> = (result[method] = createEvent())
    sample({
      clock: event,
      source: { params: $params, data: $data },
      fn: ({ params, data }, arg) => fn(arg, params, data),
      target: fx,
    })
  }

  return result
}
