import { createEffect } from 'effector'
import { type Search, type SearchSuggestion } from './index.h'

export { type Search, type SearchSuggestion }

type GetParams = { q: string; limit?: number }

// *
// * base API effects
// *

export const base = {
  getFx: createEffect<GetParams, Search>(),
  getSuggestionsFx: createEffect<GetParams, SearchSuggestion>(),
}
