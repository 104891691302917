import type { Collection } from '@setplex/tria-api'
import type { ApiContentType } from '../../index.h'
import { toDate } from '../../tools'
import type { ApiCollection } from './index.h'

export const format = ({
  id,
  title,
  description,
  horizontalImage,
  createDate,
  background,
  contentTypes,
}: ApiCollection & ApiContentType): Collection & ApiContentType => ({
  id,
  title,
  description,
  horizontalImage,
  createDate: toDate(createDate),
  background,
  contentTypes,
})
