/**
 * Function formats firstName and lastName like: Eugen Zhu ---> EZ
 * or Eugen Li ---> EL (if firstName consists of two words Eugen Li)
 * function works with symbols, etc.
 * @param firstName
 * @param lastName
 */

export function formatUsername({
  firstName,
  lastName,
}: {
  firstName: string | null | undefined
  lastName: string | null | undefined
}): string {
  if (!firstName && !lastName) return 'NA'

  if (!lastName) {
    return (
      firstName
        ?.split(' ')
        .map((x) => x[0])
        .filter((x) => x) // filter if there are many spaces between words
        .slice(0, 2)
        .join('') || 'NA'
    )
  }

  return (firstName?.[0] || '') + (lastName?.[0] || '')
}
