import type { API } from '@setplex/tria-api'
import type { HttpClient } from '../../http'

export function use(
  http: HttpClient,
  privacy: API['privacy'],
  _api: API
): void {
  // POST /api/web/feedback/privacy-data
  privacy.setPrivacyOptOutFx.use(async (params) => {
    const json = await http.post('/feedback/privacy-data', {
      body: JSON.stringify(params),
    })

    if (!json) {
      throw new Error('Empty answer in privacy.setPrivacyOptOutFx')
    }
  })

  // POST /api/web/feedback/sensitive-data-request
  privacy.setPrivacySensitiveDataFx.use(async (params) => {
    const json = await http.post('/feedback/sensitive-data-request', {
      body: JSON.stringify(params),
    })

    if (!json) {
      throw new Error('Empty answer in privacy.setPrivacySensitiveDataFx')
    }
  })
}
