import { createEffect, type Effect } from 'effector'
import type { GenericApiResponse } from '../../../interfaces/generic'
import type { UsersPreferences } from '../../onboarding'

export type { UsersPreferences }

export const getExtraFx: Effect<
  void,
  UsersPreferences,
  GenericApiResponse<Error>
> = createEffect()
