import { split, type Event } from 'effector'
import { type RouterResponse } from './router'

// stages
//  - 'navigate' is used for initial route render,
//  - 'refresh' is used for re-initialize data for route
export type Staged = {
  stage: 'navigate' | 'refresh'
}

export const stage = {
  // extend route object with `navigate` stage
  navigate: (route: RouterResponse): RouterResponse & Staged => ({
    ...route,
    stage: 'navigate',
  }),

  // extend route object with `refresh` stage
  refresh: (route: RouterResponse): RouterResponse & Staged => ({
    ...route,
    stage: 'refresh',
  }),
}

export const stages = <T extends Staged>(
  init: Event<T>
): {
  navigate: Event<T>
  refresh: Event<T>
} =>
  split(init, {
    navigate: ({ stage }) => stage === 'navigate',
    refresh: ({ stage }) => stage === 'refresh',
  })
