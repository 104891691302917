import type { ApiCommonResponse, RequestInput } from '@setplex/tria-api'
import { createEffect, type Effect } from 'effector'

/**
 * Base prefix for all Nora API request
 */
const base: string = '/api/web'

/**
 * Utility type to assert OK response type from Nora API
 * (and keep old API response untouched)
 */
export type OK<T> = T extends { status?: 'OK' | 'ERROR' | undefined }
  ? Omit<T, 'status'> & { status: 'OK' }
  : T

/**
 * Utility type to assert ERROR response type from Nora API
 * (and keep old API response untouched)
 */
export type ERROR<T> = T extends { status?: 'OK' | 'ERROR' | undefined }
  ? Omit<T, 'status' | 'errorCode'> & { status: 'ERROR'; errorCode: string }
  : T

/**
 *
 */
export const requestFx: Effect<
  { url: string; init: RequestInit },
  { json?: ApiCommonResponse; error?: Error }
> = createEffect()

/**
 * Type for HTTP client
 */
export type HttpClient = ReturnType<typeof http>

/**
 * Creates HTTP client, to interract with Nora API
 */
export function http(fx: Effect<RequestInput, Response>) {
  requestFx.use(async ({ url, init }) => {
    // adjust headers
    init.headers = new Headers(init.headers)

    // `Accept: application/json` by default
    if (!init.headers.has('Accept')) {
      init.headers.set('Accept', 'application/json')
    }

    // `Content-Type: application/json` by default for action requests
    const method = (init.method || 'GET').toUpperCase()
    if (
      ['PUT', 'POST', 'PATCH'].includes(method) &&
      !init.headers.has('Content-Type') &&
      !['/feedback/support'].includes(url)
    ) {
      init.headers.set('Content-Type', 'application/json')
    }

    // send request
    let response: Response | undefined
    let error: Error | undefined
    try {
      response = await fx({ input: base + url, init })
    } catch (e: any) {
      console.log('fx error:', base + url, '->', e.toString())
      error = e
      response = e.response
    }

    const isJsonParsable = response?.headers
      .get('content-type')
      ?.includes('application/json')

    let json: any = null
    if (response && isJsonParsable) {
      // try to decode json in any case
      // FIXME: do not `.json` if not JSON (content-length)
      // const contentLength = Number(response?.headers.get('content-length')) // 'content-length' blocked by cors !
      try {
        json = await response.clone().json()
      } catch (e) {
        console.log('http.request error: ', e)
      }
    }

    return { json, error }
  })

  const request =
    (def: Partial<RequestInit> = {}) =>
    async <T>(url: string, requestInit: RequestInit = {}): Promise<OK<T>> => {
      const { json, error } = await requestFx({
        url,
        init: { ...def, ...requestInit },
      })

      // filter responses with status === 'ERROR' but Status Code: 200 OK
      if (!error && json && json.status === 'ERROR') {
        return json as OK<any>
      }
      // filter out OK responses (and should keep old version responses)
      if (error || (json && 'status' in json && json.status !== 'OK')) {
        throw { error, payload: json as ERROR<any> }
      }

      return json as OK<any>
    }

  return {
    get: request({ method: 'GET' }),
    put: request({ method: 'PUT' }),
    post: request({ method: 'POST' }),
    patch: request({ method: 'PATCH' }),
    delete: request({ method: 'DELETE' }),
  }
}
