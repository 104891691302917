import { type IUTMParams } from '@setplex/tria-api'

export const getParamsFromUrl = (href?: string): IUTMParams => {
  const { searchParams: s } = new URL(href ?? window.location.href)
  return {
    utm_source: s.has('utm_source') ? s.get('utm_source') : '',
    utm_medium: s.has('utm_medium') ? s.get('utm_medium') : '',
    utm_campaign: s.has('utm_campaign') ? s.get('utm_campaign') : '',
    utm_term: s.has('utm_term') ? s.get('utm_term') : '',
    utm_content: s.has('utm_content') ? s.get('utm_content') : '',
  }
}
