import {
  createEvent,
  sample,
  split,
  type Event,
  type EventPayload,
} from 'effector'
import { type RequestInput } from '../index.h'

// *
// * Base HTTP events
// *

export const request: Event<{
  input: RequestInput
}> = createEvent()

export const response: Event<{
  input: RequestInput
  error?: Error
  request?: Request
  response: Response
}> = createEvent()

export const error: Event<{
  input: RequestInput
  error: Error
}> = createEvent()

// *
// * HTTP errors
// *

// general
export const httpError = createEvent<EventPayload<typeof response>>()
sample({
  clock: response,
  filter: ({ response }) => !response.ok,
  target: httpError,
})

// prettier-ignore
export const {
  http400, badRequest                       = http400,
  http401, unauthorized                     = http401,
  http402, paymentRequired                  = http402,
  http403, forbidden                        = http403,
  http404, notFound                         = http404,
  http405, methodNotAllowed                 = http405,
  http406, notAcceptable                    = http406,
  http407, proxyAuthenticationRequired      = http407,
  http408, requestTimeout                   = http408,
  http418, teapot                           = http418,
  http423, locked                           = http423,
  http429, tooManyRequests                  = http429,
  http450, blockedByWindowsParentalControls = http450,
  http451, unavailableForLegalReasons       = http451,
  http500, internalServerError              = http500,
  http501, notImplemented                   = http501,
  http502, badGateway                       = http502,
  http503, serviceUnavailable               = http503,
  http504, gatewayTimeout                   = http504,
  http52x, cloudflareError                  = http52x,
}: {
  [kind: string]: Event<EventPayload<typeof response>>
} = split(httpError, {
  http400: ({ response }) => response.status === 400,
  http401: ({ response }) => response.status === 401,
  http402: ({ response }) => response.status === 402,
  http403: ({ response }) => response.status === 403,
  http404: ({ response }) => response.status === 404,
  http405: ({ response }) => response.status === 405,
  http406: ({ response }) => response.status === 406,
  http407: ({ response }) => response.status === 407,
  http408: ({ response }) => response.status === 408,
  http418: ({ response }) => response.status === 418,
  http423: ({ response }) => response.status === 423,
  http429: ({ response }) => response.status === 429,
  http450: ({ response }) => response.status === 450,
  http451: ({ response }) => response.status === 451,
  http500: ({ response }) => response.status === 500,
  http501: ({ response }) => response.status === 501,
  http502: ({ response }) => response.status === 502,
  http503: ({ response }) => response.status === 503,
  http504: ({ response }) => response.status === 504,

  /*
  Cloudflare specific HTTP errors:

  520 Web Server Returned an Unknown Error
    The origin server returned an empty, unknown, or unexpected response to Cloudflare.
  521 Web Server Is Down
    The origin server refused connections from Cloudflare. Security solutions at the
    origin may be blocking legitimate connections from certain Cloudflare IP addresses.
  522 Connection Timed Out
    Cloudflare timed out contacting the origin server.
  523 Origin Is Unreachable
    Cloudflare could not reach the origin server; for example, if the DNS records
    for the origin server are incorrect or missing.
  524 A Timeout Occurred
    Cloudflare was able to complete a TCP connection to the origin server,
    but did not receive a timely HTTP response.
  525 SSL Handshake Failed
    Cloudflare could not negotiate a SSL/TLS handshake with the origin server.
  526 Invalid SSL Certificate
    Cloudflare could not validate the SSL certificate on the origin web server.
    Also used by Cloud Foundry's gorouter.
  527 Railgun Error
    Error 527 indicates an interrupted connection between Cloudflare
    and the origin server's Railgun server.
  */
  http52x: ({ response }) => response.status >= 520 && response.status <= 527,
})
