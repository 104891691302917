import { createEffect } from 'effector'
import { type ContentType, type ContentTypeParams } from '../../index.h'
import { stateful } from '../../lib/stateful'
import { type Collection } from './index.h'

export { type Collection }

type GetOneParams = { id: number }
type IdsParams = { ids?: number[] }
type PageParams = { limit?: number; offset?: number }
type SortParams = { by?: string; order?: string }
type FilterParams = { q?: string; hasContent?: boolean }
type GetManyParams = IdsParams & PageParams & SortParams & FilterParams

// *
// * base API effects
// *

export const base = {
  getOneFx: createEffect<
    GetOneParams & ContentTypeParams,
    Collection & ContentType
  >(),
  getManyFx: createEffect<GetManyParams, Collection[]>(),
}

// *
// *
// *

export const pageable = (initial?: GetManyParams) =>
  stateful({
    effect: base.getManyFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void, { offset = 0, limit = 36 }) => ({
        offset: offset + limit,
      }),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
      sort: ({ by, order }: SortParams) => ({ by, order }),
      by: (by: string | undefined) => ({ by }),
      order: (order: string | undefined) => ({ order }),
      filter: (q: string | undefined) => ({ q }),
    },
  })
