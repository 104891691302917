import { createEffect } from 'effector'
import { type HeaderFxParameter } from '../../index.h'
import { http, isOk, type ApiResponse } from '../http'

export interface Features {
  socialAuth?: {
    appleEnabled?: boolean
    facebookEnabled?: boolean
    googleEnabled?: boolean
  }
  qrCodeLoginEnabled: boolean
  liveModuleEnabled: boolean
  anotherPlatforms?: {
    ios: string | null
    tvOs: string | null
    android: string | null
    fireTv: string | null
    roku: string | null
    samsung: string | null
    lg: string | null
  }
}

// TODO: fix types
export const getFx = createEffect<HeaderFxParameter, Features>(
  async (params) => {
    const { headers = {} } = params ?? {}

    const data: ApiResponse<Features> = await http
      .get('features', {
        headers,
      })
      .json()

    if (isOk(data)) {
      return data.payload || {}
    } else {
      throw data
    }
  }
)
