import { model as config, remote } from '@/config'
import type {
  IContentInfoOVP,
  IEpgInfoOVP,
  VideoSourceInfoOVP,
} from '@setplex/tria-api'
import {
  createEvent,
  createStore,
  sample,
  Store,
  type EventCallable,
} from 'effector'
import { selectEpgDateModel } from '~/features/epg/dates'
import { ONE_DAY_MS, PLAYBACK_TRIGGER } from '~/shared/constants'
import { channelsEntity } from '../channels'
import { epgDatesModel } from '../epg/dates'

export const play: EventCallable<VideoSourceInfoOVP> = createEvent()
export const stop: EventCallable<VideoSourceInfoOVP> = createEvent()
export const watchingContent: EventCallable<IContentInfoOVP> = createEvent()
export const playerWatching: EventCallable<VideoSourceInfoOVP> = createEvent()
export const playerEvent: EventCallable<VideoSourceInfoOVP> = createEvent()

export const setPlayerSessionId = createEvent<string>()

export const $playerSessionId = createStore<string>('').on(
  setPlayerSessionId,
  (_, data) => data
)

// Collect data about playbackTrigger
export const setPlaybackTrigerData = createEvent<string>()

export const $playbackTrigerData = createStore<string>(
  PLAYBACK_TRIGGER.playerOpen
).on(setPlaybackTrigerData, (_, data) => data)

// store to collect data received from player (duration, audioLanguage, textLanguage, startedTime etc.)
// need to store as for live we should wait for data about epg
// need only for event 'play_start', there is no necessity for other events for current selected channel (as will be used received data)
export const resetPlayerData = createEvent()
export const $playerData = createStore<VideoSourceInfoOVP | null>(null).reset(
  resetPlayerData
)

sample({
  clock: play,
  target: $playerData,
})

// store and events for 'epg_view' event analytics
export const sendEpgViewEventData = createEvent()
export const $epgViewEventData = createStore<IEpgInfoOVP | null>(null)

sample({
  source: {
    current: channelsEntity.$channel,
    activeDay: selectEpgDateModel.$active,
    today: epgDatesModel.$today,
  },
  filter: ({ current, activeDay, today }) =>
    Boolean(current) && Boolean(activeDay) && Boolean(today),
  fn: ({ current, activeDay, today }) => {
    if (!current || !activeDay || !today) return null
    const todayInMS = new Date(today.year, today.month, today.day).getTime()
    const activeDayInMs = new Date(
      activeDay.year,
      activeDay.month,
      activeDay.day
    ).getTime()
    const tabId = (activeDayInMs - todayInMS) / ONE_DAY_MS

    return {
      channelId: current.id ? String(current.id) : '',
      channelTitle: current.title || '',
      tabId: String(tabId),
    }
  },
  target: $epgViewEventData,
})

// Firebase flag value
export const $playerWatchingEventInterval: Store<number | undefined> =
  config.get(remote.uvo_playerWatchingEventInterval)
