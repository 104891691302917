/* eslint-disable @typescript-eslint/no-explicit-any */
import ky from 'ky'
import { env } from '~/app/environment'

export const REQUEST: unique symbol = Symbol('request')
export const RESPONSE: unique symbol = Symbol('response')

// type KyInstance = typeof ky
export interface IClient {
  (input: RequestInfo, init?: RequestInit): Promise<
    Response & {
      [REQUEST]: Request
      [RESPONSE]: Response
    }
  >
}

export const client: IClient = ky.extend({
  // prefixUrl: '/api/proxy/',
  retry: 0,

  // http request timeout
  timeout: Number.isFinite(env.UVO_CLIENT_HTTP_TIMEOUT)
    ? env.UVO_CLIENT_HTTP_TIMEOUT
    : false, // `false` means 'no timeout' (https://github.com/sindresorhus/ky#timeout)

  hooks: {
    beforeError: [
      (error) => {
        const { response, request } = error

        if (response) {
          Object.assign(error, {
            [REQUEST]: request,
            [RESPONSE]: response,
          })
        }

        return error
      },
    ],

    afterResponse: [
      async (request, options, response) => {
        return Object.assign(response, {
          [REQUEST]: request,
          [RESPONSE]: response,
        })
      },
    ],
  },
}) as any

// TODO: remove
;(window as any).ky = ky
