import { createEffect, type Effect } from 'effector'
import type { GenericApiResponse } from '../../interfaces/generic'
import type { OVPProfile } from './index.h'

export type { OVPProfile }

export const getProfileFx: Effect<
  void,
  OVPProfile,
  GenericApiResponse<Error>
> = createEffect()
export const setProfileFx: Effect<
  OVPProfile,
  void,
  GenericApiResponse<Error>
> = createEffect()
