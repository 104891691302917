import { createEffect, type Effect } from 'effector'
import type {
  ApiCommonResponse,
  GenericApiResponse,
} from '../../../interfaces/generic'
import type {
  OVPCreatorData,
  OVPSignInParams,
  OVPSignUpParams,
  OVPUserAuthData,
  OVPUserAuthEmail,
  OVPUserAuthParams,
  OVPUserAuthPass,
  OVPUserAuthToken,
  OVPUserQRAuthData,
  OVPUserSignInResponse,
} from './index.h'

export type {
  OVPSignInParams,
  OVPUserAuthData,
  OVPUserAuthEmail,
  OVPUserAuthParams,
  OVPUserAuthPass,
  OVPUserAuthToken,
  OVPUserSignInResponse,
}

export const signInFx: Effect<
  OVPSignInParams,
  OVPUserSignInResponse,
  GenericApiResponse<Error>
> = createEffect()

export const logOutFx: Effect<
  void,
  void,
  GenericApiResponse<Error>
> = createEffect()
export const activateTokenFx: Effect<
  OVPUserAuthToken,
  void,
  GenericApiResponse<Error>
> = createEffect()

export const signUpFx: Effect<
  OVPSignUpParams,
  OVPUserAuthEmail,
  GenericApiResponse<Error>
> = createEffect()

// Forgot Password effects
export const resetPasswordFx: Effect<
  OVPUserAuthEmail,
  OVPUserAuthEmail,
  GenericApiResponse<Error>
> = createEffect()
export const confirmResetPasswordFx: Effect<
  OVPUserAuthToken,
  OVPUserAuthEmail,
  GenericApiResponse<Error>
> = createEffect()
export const setPasswordFx: Effect<
  OVPUserAuthPass & OVPUserAuthToken,
  void,
  GenericApiResponse<Error>
> = createEffect()

export const creatorJoinFx: Effect<
  OVPCreatorData,
  OVPUserAuthEmail,
  GenericApiResponse<Error>
> = createEffect()

// QR Code effects
export const getInfoViaQRCodeFx: Effect<
  OVPUserAuthToken,
  OVPUserQRAuthData,
  GenericApiResponse<Error>
> = createEffect()
export const confirmSignInByQRCodeFx: Effect<
  OVPUserAuthToken,
  ApiCommonResponse,
  GenericApiResponse<Error>
> = createEffect()
