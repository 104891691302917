import type { OVPProfile } from '@setplex/tria-api'
import type { ApiOVPProfile } from './index.h'

export const format = ({
  firstName,
  lastName,
  birthday,
  gender,
  primaryLanguage,
}: ApiOVPProfile): OVPProfile => ({
  firstName,
  lastName,
  birthday,
  gender,
  primaryLanguage,
})

export const formatParams = ({
  firstName,
  lastName,
  birthday,
  gender,
  primaryLanguage,
}: OVPProfile): ApiOVPProfile => ({
  firstName,
  lastName,
  birthday,
  gender,
  primaryLanguage,
})
