import { SPECIAL_HEADER, int } from '!/api'
import { model as router } from '!/router'
import { LocalStorageKey } from '@/constants'
import { createEvent, createStore, sample, type EventCallable } from 'effector'
import { model as session } from '~/entities/session'
import { ERROR_CODE, PATH } from '~/shared/constants'
import { T } from '~/shared/helpers'

export const init: EventCallable<void> = createEvent()
export const $isGeoblock = createStore<boolean | null>(null)
export const $isLaunchDataReady = createStore(false).on(
  int.launch.checkFx.finally,
  T
)

// Set header only if guest has selected the language
sample({
  clock: init,
  source: {
    isNotAuthenticated: session.$isNotAuthenticated,
  },
  fn: ({ isNotAuthenticated }) => {
    const language = window.localStorage.getItem(
      LocalStorageKey.PrimaryLanguage
    )
    const languageId = language
      ? JSON.parse(language)?.primaryLanguage?.id
      : null

    let headers = {}

    if (isNotAuthenticated && languageId) {
      headers = {
        [SPECIAL_HEADER.CLIENT_PRIMARY_LANGUAGE]: String(languageId),
      }
    }

    return { headers }
  },
  target: int.launch.checkFx,
})

sample({
  source: int.launch.checkFx.doneData,
  fn: (data) => data?.errorCode === ERROR_CODE.CL0002,
  target: $isGeoblock,
})

sample({
  source: { href: router.$href, isGeoblock: $isGeoblock },
  filter: ({ href, isGeoblock }) => {
    const isNoNeedToBlock =
      href === PATH.CREATORS ||
      href === PATH.ABOUT ||
      href === PATH.STATIC_SUPPORT ||
      href === PATH.TERMS_OF_USE ||
      href === PATH.STATIC_PRIVACY ||
      href === PATH.INDUSTRY ||
      href === PATH.GEOBLOCK
    return Boolean(isGeoblock) && !isNoNeedToBlock
  },
  fn: () => PATH.GEOBLOCK,
  target: router.navigatePush,
})

sample({
  source: {
    href: router.$href,
    isGeoblock: $isGeoblock,
    isLaunchDataReady: $isLaunchDataReady,
  },
  filter: ({ href, isGeoblock, isLaunchDataReady }) =>
    !isGeoblock && href === PATH.GEOBLOCK && isLaunchDataReady,
  fn: () => PATH.HOME,
  target: router.navigatePush,
})

export const $customErrorCode = createStore<string>('')

sample({
  clock: int.launch.checkFx.doneData,
  fn: (data) => data?.errorCode || '',
  target: $customErrorCode,
})
