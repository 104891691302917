export type EpgDate = {
  year: number
  month: number
  day: number
}

export type EpgDateEx = EpgDate & {
  isToday: boolean
  isYesterday: boolean
  isTomorrow: boolean
  title?: string
}

export const DEFAULT_WEEK_DURATION = 8
