/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  is,
  sample,
  type Effect,
  type EventCallable,
  type StoreWritable,
  type Unit,
} from 'effector'

export function lookbehind<T>({
  source,
  after,
  clear,
  within = 0, // next event cycle by default
  target = createEvent<T>(),
}: {
  source: Unit<T>
  after?: Unit<any>
  clear?: Unit<any>
  within?: number
  target?: EventCallable<T> | StoreWritable<T> | Effect<T, any, any>
}) {
  if (!is.unit(source))
    throw new TypeError('source must be a unit from effector')
  if (!is.unit(after) && !is.unit(clear))
    throw new TypeError('after or clear must be a unit from effector')
  if (!is.unit(target))
    throw new TypeError('target must be a unit from effector')

  const $pass = createWindow({ open: after, def: !after, timeframe: within })
  const $block = createWindow({ open: clear, timeframe: within })

  sample({
    source,
    filter: combine($pass, $block, (pass, block) => pass && !block),
    target: target as any,
  })

  return target
}

function createWindow({
  open,
  def = false,
  timeframe,
}: {
  open?: Unit<any>
  def?: boolean
  timeframe: number
}) {
  const $open = createStore<boolean>(def)

  if (open) {
    $open.on(open, () => true) // should open window as soon as possible

    const openFx = createEffect<void, void>(() => {
      return new Promise((resolve) => setTimeout(resolve, timeframe))
    })

    sample({
      clock: open,
      target: openFx,
    })

    sample({
      clock: openFx.done,
      filter: openFx.pending.map((pending) => !pending),
      fn: () => false,
      target: $open,
    })
  }

  return $open
}
