import { model as router } from '!/router'
import { F, T } from '@/helpers'
import {
  email,
  equalTo,
  maxLength,
  minLength,
  password,
  required,
} from '@/validators'
import { api } from '@setplex/tria-api'
import {
  attach,
  createEvent,
  createStore,
  sample,
  type EventCallable,
  type StoreWritable,
} from 'effector'
import { createForm } from 'effector-forms'
import { condition } from 'patronum'
import { model as session } from '~/entities/session'
import {
  LINKEDIN_CONVERSION_ID_FOR_SIGN_UP_SUBMIT_BTN,
  tracking,
} from '~/entities/tracking'
import { PATH } from '~/shared/constants/path'

// init page
export const init: EventCallable<{ query: URLSearchParams }> = createEvent()

export const waitFx = session.checkStatusFx

// sign up effect
export const signUpFx = attach({ effect: api.user.signUpFx })

// sign up form
export const signUpForm = createForm({
  fields: {
    email: {
      init: '' as string,
      rules: [required(), email(), maxLength(50)],
    },
    password: {
      init: '' as string,
      rules: [required(), minLength(6), maxLength(64), password()],
    },
    confirm: {
      init: '' as string,
      rules: [required(), equalTo('password')],
    },
  },
  validateOn: ['submit', 'blur'],
})

// send sign up request
sample({
  clock: signUpForm.formValidated,
  fn: ({ email, password }) => ({
    email: email.trim(),
    password,
  }),
  target: signUpFx,
})

export const resetErrorOnClick: EventCallable<void> = createEvent()

// error to show to the user
export const $error: StoreWritable<string | null> = createStore<string | null>(
  null
)
  .on(signUpFx.failData, (_, error) => error?.payload?.message || '')
  .reset(init, resetErrorOnClick)

sample({
  clock: signUpFx.doneData,
  fn: () => PATH.SIGN_UP_CONFIRM,
  target: router.navigatePush,
})

signUpFx.done.watch(() =>
  tracking.lintrk(LINKEDIN_CONVERSION_ID_FOR_SIGN_UP_SUBMIT_BTN)
)

export const openSignUpPopup: EventCallable<void> = createEvent()
export const closeSignUpPopup: EventCallable<void> = createEvent()

export const checkEnableToClose = createEvent()

sample({
  clock: signUpFx.done,
  target: closeSignUpPopup,
})

condition({
  source: checkEnableToClose, //FIXME: fix typescript error
  if: $error,
  then: resetErrorOnClick,
  else: closeSignUpPopup,
})

sample({
  clock: [init, openSignUpPopup, signUpFx.done],
  target: signUpForm.reset,
})

export const $isOpenedSignUpPopup: StoreWritable<boolean> =
  createStore<boolean>(false) //
    .on(openSignUpPopup, T)
    .on(closeSignUpPopup, F)

export const $enableSocialAuth: StoreWritable<boolean> =
  createStore<boolean>(true)

// parse query params to detect 'withSocialAuth' param to be able to ignore social authentication for mobile platforms
sample({
  clock: init,
  filter: ({ query }) => query.has('withSocialAuth'),
  fn: ({ query }) => {
    const withSocialAuth = query.get('withSocialAuth')
    return withSocialAuth ? JSON.parse(withSocialAuth) : true
  },
  target: $enableSocialAuth,
})

const $isSignUpPage = router.$location.map(
  (location) => location?.pathname.includes(PATH.SIGN_UP) ?? false
)

sample({
  clock: [init, session.$isAuthenticated],
  source: {
    isAuthenticated: session.$isAuthenticated,
    isSignUpPage: $isSignUpPage,
  },
  filter: ({ isAuthenticated, isSignUpPage }) =>
    Boolean(isAuthenticated && isSignUpPage),
  fn: () => PATH.HOME,
  target: router.navigatePush,
})
