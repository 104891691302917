import type { API, GenericApiResponse } from '@setplex/tria-api'
import type { HttpClient } from '../../../http'
import type { ApiUsersPreferences } from '../../onboarding'

export function use(http: HttpClient, users: API['users'], _api: API): void {
  // GET /api/web/users/extra
  users.getExtraFx.use(async () => {
    const json = await http.get<GenericApiResponse<ApiUsersPreferences>>(
      `/users/extra`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in users.getExtraFx')
    }

    return json.payload
  })
}
