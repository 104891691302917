/* eslint-disable @typescript-eslint/no-explicit-any */
import ky from 'ky'
import { appVersion, env, runtime } from '~/app/environment'
import { APP_PLATFORM, HEADER } from '../index.h'

export type ApiResponse<T = any> = {
  status: 'OK' | 'ERROR'
  errorCode?: string | null
  message?: string | null
  payload?: T | null
}

export type ApiOk<T = any> = ApiResponse<T> & { status: 'OK' }
export type ApiError<T = any> = ApiResponse<T> & { status: 'ERROR' }

export const isOk = <T>(r: ApiResponse<T>): r is ApiOk<T> => r.status === 'OK'
export const isError = <T>(r: ApiResponse<T>): r is ApiError<T> => !isOk(r)

// Options for requests within the uvo package
export const http = ky.extend({
  prefixUrl: '/api/web/',
  retry: 0,

  // http request timeout
  timeout: Number.isFinite(env.UVO_CLIENT_HTTP_TIMEOUT)
    ? env.UVO_CLIENT_HTTP_TIMEOUT
    : false, // `false` means 'no timeout' (https://github.com/sindresorhus/ky#timeout)
  headers: {
    [HEADER.UVO_PLATFORM]: APP_PLATFORM,
    [HEADER.UVO_APP_NAME]: runtime.app.name,
    [HEADER.UVO_DEVICE_TYPE]: runtime.device.type.toUpperCase(),
    [HEADER.UVO_DEVICE_OS]: runtime.os.name || '',
    [HEADER.UVO_DEVICE_OS_VERSION]: runtime.os.version || '',
    [HEADER.UVO_DEVICE_APP_VERSION]: appVersion,
    [HEADER.UVO_WEB_METADATA]: JSON.stringify({
      browser: runtime.browser.name,
      browserVersion: runtime.browser.version,
    }),
  },
})
