export const transformSecondsToMilliseconds = (
  seconds: number | undefined
): number => {
  const ONE_SECOND_IN_MILLISECONDS = 1000

  if (!seconds) return 0
  return seconds * ONE_SECOND_IN_MILLISECONDS
}

/**
 * Format Date to ISO 8601 format -> `2022-09-19T12:30:32.683Z`
 * Always in UTC
 */
export const toISOString = (date: Date) => date.toISOString()

/**
 * Format Date to ISO 8601 format with timezone -> `2022-09-19T15:28:19+03:00`
 * Native method .toISOString() formats always to UTC
 */
export const toISOStringWithTimezone = (date: Date) => {
  const tzOffset = -date.getTimezoneOffset()
  const diff = tzOffset >= 0 ? '+' : '-'
  const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, '0')
  // prettier-ignore
  return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      diff + pad(tzOffset / 60) +
      ':' + pad(tzOffset % 60);
}
