import { model as geoblock } from '!/launch'
import { model as session } from '$/session'
import { model as config, remote } from '@/config'
import { LocalStorageKey } from '@/constants'
import { F, T, aye, nay } from '@/helpers'
import { api } from '@setplex/tria-api'
import { createEvent, createStore, sample, type Store } from 'effector'
import { persist } from 'effector-storage/local'
import { and, not, pending } from 'patronum'
import { preferencesEntity } from '~/entities/preferences'
import { profile } from '~/features/profile'
import { noLanguageSelection } from './constants'
import type { IPrimaryLanguage, IPrimaryLanguagesStorage } from './index.h'

export const init = createEvent()

export const $isDataReady = createStore(false)
export const $isOnboardingEnabled = createStore(false)

// Firebase flag value
export const $enabled: Store<boolean> = config.get(
  remote.uvo_isOnboardingEnabled
)

// TODO: will be simplified in future - in new version of request to get info can be detected is user guest or not
// Pending is used to handle cases when fx failed
export const $inProcess = pending([
  api.userSession.checkStatusFx,
  api.userSession.getInfoFx,
  profile.getProfileFx,
])

sample({
  clock: $inProcess,
  fn: nay,
  target: $isDataReady,
})

sample({
  clock: [config.$ready, $enabled, geoblock.$isGeoblock],
  source: and($enabled, not(geoblock.$isGeoblock)),
  filter: config.$ready,
  fn: aye,
  target: $isOnboardingEnabled,
})

export const openPreferencesPopup = createEvent()
export const closePreferencesPopup = createEvent()
export const $isPreferencesPopupOpened = createStore(false) //
  .on(openPreferencesPopup, T)
  .on(closePreferencesPopup, F)

export const setTryOpeningPopupToTrue = createEvent()
export const $shouldTryToOpenPopup = createStore(false)
  .on(setTryOpeningPopupToTrue, T)
  .on(openPreferencesPopup, F)

export const setLanguage = createEvent<{
  language: IPrimaryLanguage
  guestGuid?: string
  authorizedUserGuid?: string
}>()
export const updateLanguageStorage = createEvent<{
  language: IPrimaryLanguage
  guestGuid?: string
  authorizedUserGuid?: string
}>()

export const $languageStorage = createStore<IPrimaryLanguagesStorage>({
  primaryLanguage: null,
  guest: {},
  authorizedUsers: {},
}).on(updateLanguageStorage, (state, data) => {
  const { language, guestGuid, authorizedUserGuid } = data ?? {}
  let result = state

  if (guestGuid) {
    result = {
      ...state,
      primaryLanguage: language,
      guest: { [guestGuid]: language },
    }
  }

  if (authorizedUserGuid) {
    const authorizedUsers = state?.['authorizedUsers']
    const user = { [authorizedUserGuid]: language }
    result = {
      ...state,
      primaryLanguage: language,
      authorizedUsers: { ...authorizedUsers, ...user },
    }
  }

  return result
})

persist({
  store: $languageStorage,
  key: LocalStorageKey.PrimaryLanguage,
})

export const $language = $languageStorage.map(
  (storage) => storage?.primaryLanguage ?? null
)

// Update language storage if guest
sample({
  clock: setLanguage,
  source: session.$guestGuid,
  filter: and($isDataReady, session.$isGuest),
  fn: (guestGuid, language) => {
    return {
      ...language,
      guestGuid,
    }
  },
  target: updateLanguageStorage,
})

// Update language storage if authorized user
sample({
  clock: setLanguage,
  source: session.$authenticatedUserInfo,
  filter: and($isDataReady, session.$isAuthenticated),
  fn: (info, language) => {
    let data = { ...language }

    if (info?.userGuid) {
      data = {
        ...data,
        authorizedUserGuid: info?.userGuid,
      }
    }

    return data
  },
  target: updateLanguageStorage,
})

// Open popup if guest
sample({
  clock: [$isOnboardingEnabled, $isDataReady],
  source: {
    ready: $isDataReady,
    enabled: $isOnboardingEnabled,
    languageStorage: $languageStorage,
    guestGuid: session.$guestGuid,
    isGuest: session.$isGuest,
  },
  filter: ({ ready, enabled, languageStorage, guestGuid, isGuest }) => {
    const guest = languageStorage?.guest?.[guestGuid]
    const shouldLoadLanguages =
      ready && enabled && Boolean(isGuest) && Boolean(guestGuid) && !guest

    return shouldLoadLanguages
  },
  target: [setTryOpeningPopupToTrue, preferencesEntity.getOnboardingExtraFx],
})

// Open popup if authorized user
// if is first login - there is another flow!
sample({
  clock: [
    $isOnboardingEnabled,
    $isDataReady,
    session.$authenticatedUserInfo,
    session.$isFirstLogin,
    session.$isFirstLoginBySocialNetwork,
  ],
  source: {
    ready: $isDataReady,
    enabled: $isOnboardingEnabled,
    languageStorage: $languageStorage,
    info: session.$authenticatedUserInfo,
    isAuthenticated: session.$isAuthenticated,
    isFirstLogin: session.$isFirstLogin,
    isFirstLoginBySocialNetwork: session.$isFirstLoginBySocialNetwork,
  },
  filter: ({
    ready,
    enabled,
    languageStorage,
    info,
    isAuthenticated,
    isFirstLogin,
    isFirstLoginBySocialNetwork,
  }) => {
    const { authorizedUsers } = languageStorage ?? {}
    const user = info?.userGuid ? authorizedUsers[info.userGuid] : null
    const shouldLoadLanguages =
      ready &&
      enabled &&
      Boolean(isAuthenticated) &&
      Boolean(info?.userGuid) &&
      !user &&
      !isFirstLogin &&
      !isFirstLoginBySocialNetwork

    return shouldLoadLanguages
  },
  target: [setTryOpeningPopupToTrue, preferencesEntity.getExtraFx],
})

// Open popup only if languages have loaded
sample({
  clock: preferencesEntity.$languages,
  source: $shouldTryToOpenPopup,
  filter: (shouldTryToOpenPopup, languages) => {
    return (
      shouldTryToOpenPopup && Boolean(languages) && Boolean(languages?.length)
    )
  },
  target: openPreferencesPopup,
})

// Update language storage with primary language from profile (language from profile is priority)
sample({
  clock: [profile.$profile, $isDataReady, $isOnboardingEnabled],
  source: {
    ready: $isDataReady,
    enabled: $isOnboardingEnabled,
    profile: profile.$profile,
  },
  filter: ({ ready, enabled, profile }) => {
    return ready && enabled && Boolean(profile)
  },
  fn: ({ profile }) => {
    const { id, title } = profile?.primaryLanguage ?? {}
    let language = noLanguageSelection

    if (id) {
      language = {
        id,
        title: title || '',
      }
    }

    return { language }
  },
  target: setLanguage,
})
