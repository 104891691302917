export {
  type OVPSessionData,
  type OVPUserInfo,
  type OVPUserSessionData,
} from './entities/auth/session'
export {
  type OVPSignInParams,
  type OVPUserAuthData,
  type OVPUserAuthToken,
} from './entities/auth/user'
export {
  OVPBannerType,
  type OVPBannerCarousel,
  type OVPBannerContent,
  type OVPBannerParams,
  type OVPBannerTypeContent,
} from './entities/carousels'
export { type Collection } from './entities/collections'
export { type Content } from './entities/content'
export {
  type Creator,
  type CreatorAwards,
  type CreatorSocialMediaLink,
  type CreatorTag,
} from './entities/creators'
export {
  HomeRowType,
  type HomeRow,
  type HomeRowFilters,
  type HomeRows,
  type HomeRowSorting,
} from './entities/home'
export {
  type EpgInfo,
  type EpgInfoDto,
  type LiveChannel,
  type LiveChannelCategory,
  type TimeRange,
  type TvChannelWithEpg,
  type TvEpg,
  type TvEpgApiDto,
} from './entities/liveChannel'
export { type UsersPreferences } from './entities/onboarding'
export { type OtherTag } from './entities/otherTag'
export {
  type OVPPrivacyOptOut,
  type OVPPrivacySensitive,
} from './entities/privacy'
export { type OVPProfile } from './entities/profile'
export { type Search, type SearchSuggestion } from './entities/search'
export { type OVPSupport } from './entities/support'
export { type Tag } from './entities/tags'
export { type LogicError } from './events/logic'
export { RequestError, RequestFailedError, RequestHttpError } from './fx/errors'
export {
  type Adapter,
  type API,
  type Headers,
  type Page,
  type PageableInfo,
  type PageableInfoCursor,
  type PageableInfoNew,
  type PageInfo,
  type PageInfoCollection,
  type PageInfoNew,
  type PageInfoWithCursor,
  type PageNew,
  type PageWithCursor,
  type RequestInput,
} from './index.h'
export {
  ContentType,
  type AnalyticsEventTypeOVP,
  type AnalyticsGenericInfoOVP,
  type AnalyticsInfoOVP,
  type AnalyticsPlayerOpenInfoOVP,
  type AnalyticsSearchInfoOVP,
  type ApplicationInfoOVP,
  type BEAnalyticsInfoOVP,
  type IContentInfoOVP,
  type IEpgInfoOVP,
  type ISearchInfoOVP,
  type IUTMParams,
  type PlayerEventTypeOVP,
  type VideoSourceInfoOVP,
} from './interfaces/analytics_ovp'
export {
  MediaOrderType,
  MediaResolution,
  MediaTypes,
  ResourceTypes,
  VideoType,
  type ApiCommonResponse,
  type ContentStore,
  type GenericApiResponse,
  type MediaUrl,
  type WrappedData,
} from './interfaces/generic'

import { fx } from './fx'

import { type Adapter } from './index.h'

import * as events from './events'

import * as analytics_ovp from './entities/analytics_ovp'
import * as userSession from './entities/auth/session'
import * as user from './entities/auth/user'
import * as carousels from './entities/carousels'
import * as collections from './entities/collections'
import * as content from './entities/content'
import * as creators from './entities/creators'
import * as home from './entities/home'
import * as liveChannel from './entities/liveChannel'
import * as onboarding from './entities/onboarding'
import * as otherTag from './entities/otherTag'
import * as privacy from './entities/privacy'
import * as profile from './entities/profile'
import * as users from './entities/profile/users'
import * as search from './entities/search'
import * as support from './entities/support'
import * as tags from './entities/tags'

export const version: string = '__VERSION__'

/**
 * Singleton with API layer,
 * provides events/effects/stores to communicate with backend
 */
export const api = {
  fx,

  use(adapter: Adapter) {
    adapter(this)
  },

  events,

  analytics_ovp,
  carousels,
  collections,
  tags,
  content,
  creators,
  search,
  user,
  userSession,
  profile,
  users,
  onboarding,
  privacy,
  support,
  home,
  otherTag,
  liveChannel,
} as const
