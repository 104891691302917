import { int } from '!/api'
import { model as router } from '!/router'
import { attach, createStore, sample } from 'effector'
import { PATH } from '~/shared/constants'
import { logger } from '~/shared/logger'

// re-export API type
export type Features = int.features.Features

// get features flags and data
export const getFx = attach({ effect: int.features.getFx })
getFx.fail.watch((_) => logger.error('failed to load features:', _))

// features store
export const $ = createStore<Features | null>(null)
  .on(getFx.doneData, (_, features) => features)
  .on(getFx.fail, () => {})

export const $appleEnabled = //
  $.map((features) => features?.socialAuth?.appleEnabled ?? null)

export const $facebookEnabled = //
  $.map((features) => features?.socialAuth?.facebookEnabled ?? null)

export const $googleEnabled = //
  $.map((features) => features?.socialAuth?.googleEnabled ?? null)

// QR code
export const $QRCodeLoginEnabled = //
  $.map((features) => features?.qrCodeLoginEnabled ?? null)

export const $liveFeatureEnabled = //
  $.map((features) => features?.liveModuleEnabled ?? null)

// clear token if QRCodeLogin is not enabled or request failed
sample({
  clock: getFx.doneData,
  source: { query: router.$query, pathname: router.$pathname },
  filter: ({ query, pathname }, data) =>
    query.has('qrAuthToken') &&
    pathname === PATH.HOME &&
    !data?.qrCodeLoginEnabled,
  fn: () => PATH.HOME,
  target: router.navigatePush,
})
sample({
  clock: getFx.fail,
  source: { query: router.$query, pathname: router.$pathname },
  filter: ({ query, pathname }) =>
    query.has('qrAuthToken') && pathname === PATH.HOME,
  fn: () => PATH.HOME,
  target: router.navigatePush,
})

// links to appstores
export const $ios = //
  $.map((features) => features?.anotherPlatforms?.ios ?? '')
export const $tvOs = //
  $.map((features) => features?.anotherPlatforms?.tvOs ?? '')
export const $android = //
  $.map((features) => features?.anotherPlatforms?.android ?? '')
export const $lg = //
  $.map((features) => features?.anotherPlatforms?.lg ?? '')
export const $roku = //
  $.map((features) => features?.anotherPlatforms?.roku ?? '')
export const $fireTV = //
  $.map((features) => features?.anotherPlatforms?.fireTv ?? '')

export const $platforms = $.map((features) => {
  const platforms = features?.anotherPlatforms
  if (!platforms) return []

  if (platforms) {
    return Object.keys(platforms)
      .map((key) => ({
        platform: key,
        link: platforms[key],
      }))
      .sort(({ link: aLink }, { link: bLink }) =>
        aLink === bLink ? 0 : bLink ? 1 : -1
      )
  }
})
