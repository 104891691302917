import { createEffect, type Effect } from 'effector'

/*
 * Manually manage scroll position during navigations
 */

// switch off native scroll restoration behavior and handle it manually
// https://developer.chrome.com/blog/history-api-scroll-restoration/
if (typeof window !== 'undefined') {
  if (window.history && 'scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'
  }
}

type Position = [scrollX: number, scrollY: number]

// dictionary to handle scroll positions manually
// don't need reactivity here, so use just object and not Store
const positions: Record<string, Position> = Object.create(null)

// save current scroll position to the dictionary
export const pushFx: Effect<string, void> = createEffect((key: string) => {
  if (typeof window !== 'undefined') {
    positions[key] = [window.pageXOffset, window.pageYOffset]
  }
})

// remove scroll position from the dictionary
export const clearFx: Effect<string, void> = createEffect((key: string) => {
  delete positions[key]
})

// restore scroll position if it was saved before, or scroll to top
export const applyFx: Effect<string, void> = createEffect((key: string) => {
  if (typeof window !== 'undefined') {
    const pos = positions[key] || [0, 0]
    // wrap window.scrollTo into requestAnimationFrame as not always work correctly without it
    window.requestAnimationFrame(() => window.scrollTo(...pos))
  }
})
