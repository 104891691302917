import { variables } from '@setplex/arcane'
import { useLayoutEffect, type ReactNode } from 'react'

import '@setplex/arcane/styles.css'

interface Props {
  children: ReactNode
}

/**
 * Not actually a _context_ provider, just wrapper for styling
 */

ThemeProvider.displayName = 'ThemeProvider'
export function ThemeProvider({ children }: Props): JSX.Element {
  useLayoutEffect(() => document.body.classList.add(variables), [])
  return <div className={variables}>{children}</div>
}
