import {
  AuthPopupFixed,
  InfoBlockSignUp,
  InlineCloseDarkButton,
  SignUpContainerForm,
  SignUpWrapperSC,
} from '@setplex/arcane'
import { useUnit } from 'effector-react'
import { SignInForm } from './components/SignInForm'
import { $isOpenedSignInPopup, checkEnableToClose } from './model'

SignInPopup.displayName = 'SignInPopup'
export function SignInPopup() {
  const isOpenedSignInPopup = useUnit($isOpenedSignInPopup)
  const onClose = () => checkEnableToClose()

  return (
    <AuthPopupFixed
      wrapperId='sign-in'
      className='auth-popup-container-sign-in'
      isOpened={isOpenedSignInPopup}
      onClickForClosingPopup={onClose}
    >
      <SignUpWrapperSC>
        <SignUpContainerForm>
          <InlineCloseDarkButton onClick={onClose} />
          <InfoBlockSignUp
            title='Join the UVOtv community'
            description='UVOtv gives you instant access to over
           5,000 movies and shows from all around the world at no cost to you!'
            ratingValue={5}
            imageUrl='/img/sign-up-avatar.png'
          />
          <SignInForm />
        </SignUpContainerForm>
      </SignUpWrapperSC>
    </AuthPopupFixed>
  )
}
