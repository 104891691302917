export let logger = new Proxy(console, {
  get() {
    return () => {
      // do nothing
    }
  },
})

export function setLogger(newLogger: any) {
  logger = newLogger
}
