import { type Location, type RouterResponse } from '!/router'
import { Loader } from '@/ui/components/Loader'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback, errorHandler } from './ErrorFallback'
import { Page } from './Page'
import { ThemeProvider } from './ThemeProvider'

interface Props {
  location: Location
  route: RouterResponse | undefined
}

App.displayName = 'App'
export function App({ location, route }: Props): JSX.Element {
  return (
    <ThemeProvider>
      <Loader />
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={errorHandler}
        resetKeys={[location.key]}
      >
        <Page route={route} />
      </ErrorBoundary>
    </ThemeProvider>
  )
}
