export class RequestError extends Error {
  public readonly error?: Error

  constructor(error: Error) {
    super(error.message)
    this.error = error
  }
}

export class RequestHttpError extends RequestError {
  public readonly request: Request
  public readonly response: Response

  constructor(error: Error, request: Request, response: Response) {
    super(error)
    this.request = request
    this.response = response
  }
}

export class RequestFailedError extends RequestError {}
