/**
 * Send user action for LinkedIn conversion tracking
 * @param id
 */
export const lintrk = (id: number) => {
  // @ts-ignore
  if (typeof window !== 'undefined' && typeof window.lintrk !== 'undefined') {
    // @ts-ignore
    window.lintrk('track', { conversion_id: id })
  }
}
