import { model as playerModel } from '$/player'
import { api, OVPSessionData } from '@setplex/tria-api'
import { createEvent, createStore, sample, split } from 'effector'
import { persist } from 'effector-storage/local'
import { debounce } from 'patronum'
import { v4 as uuidv4 } from 'uuid'
import { getMinutesDifference } from '~/shared/helpers'

export const TIME_SESSION_MINUTES = 30

// Events

export const init = createEvent()
export const updateSessionInfo = createEvent()
export const updateAllSessionInfo = createEvent()
export const updateOnlyTimeSessionInfo = createEvent()

// Stores

// Session guid, generated in the app
export const $session = createStore<OVPSessionData | null>(null)
  .on(updateAllSessionInfo, () => ({ id: uuidv4(), changed: Date.now() }))
  .on(updateOnlyTimeSessionInfo, (session) => ({
    ...session,
    changed: Date.now(),
  }))
  .on(playerModel.play, (session) => ({
    ...session,
    changed: null,
  }))

// Window session guid, generated in the app.
// Updated if a new tab is opened or the page has been refreshed
export const $windowSessionGuid = createStore<string>('') //
  .on(init, () => uuidv4())

// preserve session in localStorage
persist({
  source: debounce($session.updates, 100),
  target: $session,
  key: 'uvo__session',
})

// update session info
sample({
  clock: api.events.http.request,
  target: updateSessionInfo,
})

sample({
  clock: playerModel.stop,
  target: updateOnlyTimeSessionInfo,
})

// different cases to check update or not session id
split({
  clock: updateSessionInfo,
  source: $session,
  match: {
    updateSessionIdAndTime: (session: OVPSessionData | null) => {
      if (session) {
        const lastChanged = session?.changed
        const minutes = lastChanged
          ? getMinutesDifference(Date.now(), lastChanged)
          : 0
        return minutes > TIME_SESSION_MINUTES
      }
      return true
    },
    updateSessionTime: (session: OVPSessionData | null) => {
      if (session) {
        const lastChanged = session?.changed
        const minutes = lastChanged
          ? getMinutesDifference(Date.now(), lastChanged)
          : 0
        return minutes <= TIME_SESSION_MINUTES
      }
      return false
    },
  },
  cases: {
    updateSessionIdAndTime: updateAllSessionInfo,
    updateSessionTime: updateOnlyTimeSessionInfo,
  },
})

// update api store
sample({
  clock: $session,
  target: api.analytics_ovp.$sessionInfo,
})

sample({
  clock: $windowSessionGuid,
  target: api.analytics_ovp.$windowSessionGuid,
})
