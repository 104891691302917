import { createEvent, createStore, sample } from 'effector'
import { model as clock } from '~/shared/clock'
import { type EpgDateEx } from './index.h'
import { generateWeek, getToday, isSameDate } from './lib'

// today
export const updateToday = createEvent()

export const $today = createStore<EpgDateEx>(getToday()) //
  .on(updateToday, (current) => {
    const today = getToday()
    return isSameDate(current)(today) ? current : today
  })

// week from today to future, including today and yesterday
export const $week = $today.map((today) => generateWeek(today))

sample({
  clock: clock.$days,
  target: updateToday,
})
