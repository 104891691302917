import type { API, GenericApiResponse } from '@setplex/tria-api'
import type { HttpClient } from '../../../http'
import type {
  ApiOVPUserAuthEmail,
  ApiOVPUserQRAuthData,
  ApiOVPUserSignInResponse,
} from './index.h'

export function use(http: HttpClient, user: API['user'], _api: API): void {
  // POST /api/web/auth/signin
  user.signInFx.use(async ({ email, password, rememberMe }) => {
    const json = await http.post<GenericApiResponse<ApiOVPUserSignInResponse>>(
      '/auth/signin',
      {
        // Field named username for backend
        body: JSON.stringify({ username: email, password, rememberMe }),
      }
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in user.signInFx')
    }

    return json.payload
  })

  // GET /api/web/auth/logout
  user.logOutFx.use(() => http.get('/auth/logout'))

  // GET /api/web/auth/activate?token=${token}
  user.activateTokenFx.use(({ token }) =>
    http.get(`/auth/activate?token=${token}`)
  )

  // POST /api/web/auth/signup
  user.signUpFx.use(async ({ email, password }) => {
    const json = await http.post<GenericApiResponse<ApiOVPUserAuthEmail>>(
      '/auth/signup',
      {
        body: JSON.stringify({ email, password }),
      }
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in user.signUpFx')
    }

    return json.payload
  })

  // Forgot password
  // POST /api/web/auth/recovery
  user.resetPasswordFx.use(async ({ email }) => {
    const json = await http.post<GenericApiResponse<ApiOVPUserAuthEmail>>(
      '/auth/recovery',
      {
        body: JSON.stringify({ email }),
      }
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in user.resetPasswordFx')
    }

    return json.payload
  })

  // GET /api/web/auth/forgot/${token}
  user.confirmResetPasswordFx.use(async ({ token }) => {
    const json = await http.get<GenericApiResponse<ApiOVPUserAuthEmail>>(
      `/auth/forgot/${token}`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in user.confirmResetPasswordFx')
    }

    return json.payload
  })

  // POST /api/web/auth/forgot
  user.setPasswordFx.use(({ password, token }) =>
    http.post('/auth/forgot', {
      body: JSON.stringify({ password, token }),
    })
  )

  // GET api/web/auth/qr/info?token={token}
  user.getInfoViaQRCodeFx.use(async ({ token }) => {
    const json = await http.get<GenericApiResponse<ApiOVPUserQRAuthData>>(
      `/auth/qr/info?token=${token}`
    )

    if (!json || !json.payload) {
      throw new Error('Empty answer in user.getInfoViaQRCodeFx')
    }

    return json.payload
  })

  // POST api/web/auth/qr/signIn?token={token}
  user.confirmSignInByQRCodeFx.use(async ({ token }) => {
    const json = await http.post(`/auth/qr/signIn?token=${token}`)
    if (!json) {
      throw new Error('Empty answer in user.confirmSignInByQRCodeFx')
    }

    return json
  })

  // POST /api/web/creators/join
  user.creatorJoinFx.use(
    async ({ name, email, phone, country, company, numberOfFilms }) => {
      const json = await http.post<GenericApiResponse<ApiOVPUserAuthEmail>>(
        '/creators/join',
        {
          body: JSON.stringify({
            name,
            email,
            phone,
            country,
            company,
            numberOfFilms,
          }),
        }
      )

      if (!json || !json.payload) {
        throw new Error('Empty answer in user.creatorSignUpFx')
      }

      return json.payload
    }
  )
}
