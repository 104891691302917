import { createEvent, type Event } from 'effector'

export interface LogicError {
  errorCode: string
  message?: string
  payload?: any
}

export const anyLogicError: Event<LogicError> = createEvent()

export const accountIpACLLock: Event<LogicError> = createEvent()
export const addressNotValid: Event<LogicError> = createEvent()
export const apiBlocked: Event<LogicError> = createEvent()
export const apiSuccess: Event<LogicError> = createEvent()
export const authNotAllowedIPAddress: Event<LogicError> = createEvent()
export const authorizationPaymentTransactionFailed: Event<LogicError> =
  createEvent()
export const badCredentials: Event<LogicError> = createEvent()
export const capturePaymentTransactionFailed: Event<LogicError> = createEvent()
export const cartLimitExceeded: Event<LogicError> = createEvent()
export const cartShouldBeChecked: Event<LogicError> = createEvent()
export const cityNotValid: Event<LogicError> = createEvent()
export const confirmationCodeInvalid: Event<LogicError> = createEvent()
export const confirmationLinkExpired: Event<LogicError> = createEvent()
export const confirmationLinkNotExist: Event<LogicError> = createEvent()
export const confirmationLinkSettingsNotFound: Event<LogicError> = createEvent()
export const contentGeoLock: Event<LogicError> = createEvent()
export const contentSetItemsChanged: Event<LogicError> = createEvent()
export const countryNotValid: Event<LogicError> = createEvent()
export const couponCannotBeApplied: Event<LogicError> = createEvent()
export const deviceAlreadyAssigned: Event<LogicError> = createEvent()
export const deviceIsDisabled: Event<LogicError> = createEvent()
export const deviceNoSubscriberAssigned: Event<LogicError> = createEvent()
export const deviceRedirectFailed: Event<LogicError> = createEvent()
export const deviceToAnotherSubscriber: Event<LogicError> = createEvent()
export const deviceTypeNotAllowed: Event<LogicError> = createEvent()
export const deviceUnassignFailed: Event<LogicError> = createEvent()
export const discountUsageLimitExceeded: Event<LogicError> = createEvent()
export const emailAlreadyConfirmed: Event<LogicError> = createEvent()
export const emailNotUnique: Event<LogicError> = createEvent()
export const emailNotValid: Event<LogicError> = createEvent()
export const enterSecurityCode: Event<LogicError> = createEvent()
export const firstNameNotValid: Event<LogicError> = createEvent()
export const guestModeDisabled: Event<LogicError> = createEvent()
export const inAppRegistrationDisabled: Event<LogicError> = createEvent()
export const internalServerError: Event<LogicError> = createEvent()
export const invalidPaymentData: Event<LogicError> = createEvent()
export const invalidReferer: Event<LogicError> = createEvent()
export const invalidRequest: Event<LogicError> = createEvent()
export const invalidRequestCode: Event<LogicError> = createEvent()
export const invoiceCodeInvalid: Event<LogicError> = createEvent()
export const invoiceCodeRequired: Event<LogicError> = createEvent()
export const lastNameNotValid: Event<LogicError> = createEvent()
export const maxNumberDevicesAssigned: Event<LogicError> = createEvent()
export const noItemsCheckout: Event<LogicError> = createEvent()
export const noItemsToBuy: Event<LogicError> = createEvent()
export const noMoreCodeRequestsAllowed: Event<LogicError> = createEvent()
export const noMoreConfirmationAttempts: Event<LogicError> = createEvent()
export const noMoreConfirmationAttemptsAllowed: Event<LogicError> =
  createEvent()
export const noMoreRequestsForEmailAllowed: Event<LogicError> = createEvent()
export const noMoreRequestsForIPAllowed: Event<LogicError> = createEvent()
export const notAvailableForPurchase: Event<LogicError> = createEvent()
export const originalPriceChanged: Event<LogicError> = createEvent()
export const packageNotAllowed: Event<LogicError> = createEvent()
export const passwordNotMatchRE: Event<LogicError> = createEvent()
export const passwordNotValid: Event<LogicError> = createEvent()
export const passwordResetBlockedForAccount: Event<LogicError> = createEvent()
export const passwordResetDisabled: Event<LogicError> = createEvent()
export const passwordTooLong: Event<LogicError> = createEvent()
export const passwordTooShort: Event<LogicError> = createEvent()
export const paymentMethodNotAvailable: Event<LogicError> = createEvent()
export const paymentMethodNotSelected: Event<LogicError> = createEvent()
export const phoneNotValid: Event<LogicError> = createEvent()
export const previousSubscriptionNotExpired: Event<LogicError> = createEvent()
export const redirectSuccessful: Event<LogicError> = createEvent()
export const refundPaymentTransactionFailed: Event<LogicError> = createEvent()
export const resetPasswordCodeInvalid: Event<LogicError> = createEvent()
export const resetPasswordCodeSessionExpired: Event<LogicError> = createEvent()
export const serverUnderMaintenance: Event<LogicError> = createEvent()
export const sessionInvalid: Event<LogicError> = createEvent()
export const stateNotValid: Event<LogicError> = createEvent()
export const subscriberMissingProfile: Event<LogicError> = createEvent()
export const subscriberPasswordRequired: Event<LogicError> = createEvent()
export const subscriberUsernameRequired: Event<LogicError> = createEvent()
export const subscriptionNotActive: Event<LogicError> = createEvent()
export const timezoneNotValid: Event<LogicError> = createEvent()
export const TOANotAccepted: Event<LogicError> = createEvent()
export const tooManyCodeRequests: Event<LogicError> = createEvent()
export const unexpectedError: Event<LogicError> = createEvent()
export const unhandledError: Event<LogicError> = createEvent()
export const unsupportedPlatform: Event<LogicError> = createEvent()
export const usernameNotNotMatchRE: Event<LogicError> = createEvent()
export const usernameNotUnique: Event<LogicError> = createEvent()
export const usernameTooLong: Event<LogicError> = createEvent()
export const voidPaymentTransactionFailed: Event<LogicError> = createEvent()
export const webDeviceInitError: Event<LogicError> = createEvent()
export const webDeviceNotFound: Event<LogicError> = createEvent()
export const yourIPAddressLocked: Event<LogicError> = createEvent()
export const ZIPNotValid: Event<LogicError> = createEvent()
