import { createEffect, createStore } from 'effector'
import { type ApiResponseError } from '../../interfaces/errors'
import { stateful } from '../../lib/stateful'
import { type Content } from './index.h'

export { type Content }

type PageParams = { limit?: number; offset?: number }
type SortParams = { by?: string; order?: string }
type SearchParams = { q?: string }
type FilterParams = SearchParams & {
  creatorId?: number
}
type OldFilterParams = SearchParams & {
  tagIds?: number[]
  collectionIds?: number[]
  creatorId?: number
}
type GetByIdParams = { getByTag?: boolean; id?: number }
type GetManyParams = GetByIdParams & PageParams & SortParams & FilterParams
type GetManyOldParams = PageParams & SortParams & OldFilterParams
type RequestHeaders = { headers: Headers }
type GetOneParams = { id: number }
type GetFavoriteParams = {
  id: number
  contentTitle?: string
  creatorId?: number
  contentUrl?: string
  isFavorite: boolean
}

// *
// * base API effects
// *

export const base = {
  getManyOldFx: createEffect<GetManyOldParams, Content[]>(),
  getManyFx: createEffect<GetManyParams, Content[]>(),
  getRecommendedFx: createEffect<
    PageParams & SortParams & SearchParams,
    Content[]
  >(),
  getFavoritesFx: createEffect<PageParams, Content[]>(),
  addToFavoritesFx: createEffect<GetFavoriteParams, void>(),
  removeFromFavoritesFx: createEffect<GetFavoriteParams, void>(),
  getOneFx: createEffect<GetOneParams & RequestHeaders, Content>(),
}

// *
// *
// *

// GET /api/web/content/by-tag/{id} (by default), /api/web/content/by-collection/{id}
export const pageable = (initial?: GetManyParams) =>
  stateful({
    effect: base.getManyFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void, { offset = 0, limit = 36 }) => ({
        offset: offset + limit,
      }),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
      sort: ({ by, order }: SortParams) => ({ by, order }),
      by: (by: string | undefined) => ({ by }),
      order: (order: string | undefined) => ({ order }),
      filter: (q: string | undefined) => ({ q }),
    },
  })

// Used at search page for content loading in grid
export const pageableOld = (initial?: GetManyOldParams) =>
  stateful({
    effect: base.getManyOldFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void, { offset = 0, limit = 36 }) => ({
        offset: offset + limit,
      }),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
      sort: ({ by, order }: SortParams) => ({ by, order }),
      by: (by: string | undefined) => ({ by }),
      order: (order: string | undefined) => ({ order }),
      filter: (q: string | undefined) => ({ q }),
    },
  })

export const pageableFavorites = (initial?: PageParams) =>
  stateful({
    effect: base.getFavoritesFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      /* The logic for changing offset has been moved to the effector model
      because offset should change when items are removed from favorites */
      next: (_: void) => ({}),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
    },
  })

export const pageableRecommended = (
  initial?: PageParams & SortParams & SearchParams
) =>
  stateful({
    effect: base.getRecommendedFx,
    initial,
    methods: {
      limit: (n: number) => ({ limit: n }),
      offset: (n: number) => ({ offset: n }),
      next: (_: void, { offset = 0, limit = 36 }) => ({
        offset: offset + limit,
      }),
      prev: (_: void, { offset = 0, limit = 36 }) => ({
        offset: Math.max(offset - limit, 0),
      }),
    },
  })

// Errors

export const error = {
  getOneFx: createStore<null | ApiResponseError>(null).on(
    base.getOneFx.fail,
    (_, { error }) => {
      // @ts-ignore because error does not exist in type Error
      const response = error?.error?.response
      if (!response) return

      return {
        code: response.status,
        message: response.statusText,
      }
    }
  ),
}
