import { MAX_YEAR } from '@/constants'
import { type Rule } from 'effector-forms'

export const required = (): Rule<string> => ({
  name: 'required',
  validator: (value) => Boolean(value),
})

export const email = (): Rule<string> => ({
  name: 'email',
  validator: (value) => /.+@.+\..+$/.test(value),
})

export const password = (): Rule<string> => ({
  name: 'password',
  validator: (value) => /^[A-Za-z0-9@#$_&\-*!?.]+$/.test(value),
})

export const username = (): Rule<string> => ({
  name: 'username',
  validator: (value) => /^[A-Za-z0-9#$_&\-*!?.\s]+$/.test(value),
})

export const confirmPass = (errorText: string): Rule<string> => ({
  name: 'confirmPassword',
  validator: (confirmPassword, { password }) => ({
    isValid: confirmPassword === password,
    errorText,
  }),
})

export const minLength = (min: number): Rule<string> => ({
  name: 'minLength',
  validator: (value) => value.length >= min,
})

export const maxLength = (max: number): Rule<string> => ({
  name: 'maxLength',
  validator: (value) => value.length <= max,
})

export const equalTo = (field: string): Rule<string> => ({
  name: 'equalTo',
  validator: (value, form) => value === form[field],
})

export const digits = (): Rule<string> => ({
  name: 'digits',
  validator: (value) => {
    if (!value) return true
    return /^\d+$/.test(value)
  },
})

export const age = (): Rule<string> => ({
  name: 'age',
  validator: (value) => 0 < Number(value) && Number(value) <= 99,
})

export const birthDate = (): Rule<string> => ({
  name: 'birthDate',
  validator: (value) => {
    if (!value) return true

    const date = value.split('/')
    const month = date[0]
    const day = date[1]
    const year = date[2]
    if (!month || !day || !year) return false

    const isLeapYear = new Date(Number(year), 1, 29).getMonth() === 1

    let maxDaysAmount: number
    if (['01', '03', '05', '07', '09', '10', '12'].includes(month)) {
      maxDaysAmount = 31
    } else {
      // check how many days in february
      maxDaysAmount = isLeapYear ? 29 : 28
    }
    if (['04', '06', '09', '11'].includes(month)) {
      maxDaysAmount = 30
    }

    const isMonthValid = Number(month) <= 12
    const isDayValid = Number(day) <= Number(maxDaysAmount)
    const isYearValid = year.length === 4

    return isMonthValid && isDayValid && isYearValid
  },
})

export const isTrue = (): Rule<boolean> => ({
  name: 'isTrue',
  validator: (value) => value,
})

export const limitSize = (size: number): Rule<Blob> => ({
  name: 'limitSize',
  validator: (value) => {
    if (!value) return true
    return value.size < size
  },
})

export const isDatePassed = (): Rule<string> => ({
  name: 'isDatePassed',
  validator: (value) => {
    if (!value) return true

    const todayDate = new Date()
    const pickedDate = new Date(Date.parse(value))

    return pickedDate <= todayDate
  },
})

export const isDateTooOld = (): Rule<string> => ({
  name: 'isDateTooOld',
  validator: (value) => {
    if (!value) return true

    const year = new Date().getFullYear()
    const pickedYear = new Date(Date.parse(value)).getFullYear()

    return year - MAX_YEAR <= pickedYear
  },
})
