import { LocalStorageKey } from '@/constants'
import {
  RequestFailedError,
  RequestHttpError,
  type RequestInput,
} from '@setplex/tria-api'
import { appVersion, runtime } from '~/app/environment'
import { model as session } from '~/entities/session'
import { REQUEST, RESPONSE, client } from './client'
import { APP_PLATFORM, HEADER, SPECIAL_HEADER } from './index.h'

export function request(input: RequestInput): Promise<Response> {
  let init: RequestInit | undefined
  if (!(typeof input === 'string' || input instanceof Request)) {
    init = input.init
    input = input.input
  }

  // remove leading slash, otherwise `got` is not working
  // if (typeof input === 'string' && input.startsWith('/')) {
  //   input = input.slice(1)
  // }

  if (init && init.headers && init.headers instanceof Headers) {
    init.headers.set(HEADER.UVO_PLATFORM, APP_PLATFORM)
    init.headers.set(HEADER.UVO_APP_NAME, runtime.app.name)
    init.headers.set(HEADER.UVO_DEVICE_TYPE, runtime.device.type.toUpperCase())
    init.headers.set(HEADER.UVO_DEVICE_OS, runtime.os.name || '')
    init.headers.set(HEADER.UVO_DEVICE_OS_VERSION, runtime.os.version || '')
    init.headers.set(HEADER.UVO_DEVICE_APP_VERSION, appVersion)
    init.headers.set(
      HEADER.UVO_WEB_METADATA,
      JSON.stringify({
        browser: runtime.browser.name,
        browserVersion: runtime.browser.version,
      })
    )

    // Set header only if guest has selected the language
    const language = window.localStorage.getItem(
      LocalStorageKey.PrimaryLanguage
    )
    const languageId = language
      ? JSON.parse(language)?.primaryLanguage?.id
      : null

    // eslint-disable-next-line effector/no-getState
    if (session.$isNotAuthenticated.getState() && languageId) {
      init.headers.set(
        SPECIAL_HEADER.CLIENT_PRIMARY_LANGUAGE,
        String(languageId)
      )
    }
  }

  try {
    return client(input, init)
      .then((result) => {
        return result[RESPONSE]
      })
      .catch((error) => {
        // cound be TypeError, AbortError, TimeoutError
        // https://developer.mozilla.org/en-US/docs/Web/API/fetch
        // TODO: add AbortError handling
        throw error[RESPONSE]
          ? new RequestHttpError(error, error[REQUEST], error[RESPONSE])
          : new RequestFailedError(error)
      })
  } catch (error) {
    throw new RequestFailedError(error as Error)
  }
}
