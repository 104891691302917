import { SPECIAL_HEADER, type HeaderFxParameter } from '!/api'
import { createEvent, sample, type EventCallable } from 'effector'
import { model as session } from '~/entities/session'
import { preferences } from '~/features/preferences'
import * as features from './features'
import * as modules from './modules'

export const init: EventCallable<void> = createEvent()

// load features on init
// Set header only if guest has selected the language
sample({
  clock: init,
  source: {
    isNotAuthenticated: session.$isNotAuthenticated,
    language: preferences.$language,
  },
  fn: ({ isNotAuthenticated, language }) => {
    const languageId = language?.id
    let headers = {}

    if (isNotAuthenticated && languageId) {
      headers = {
        [SPECIAL_HEADER.CLIENT_PRIMARY_LANGUAGE]: String(languageId),
      }
    }
    return { headers }
  },
  target: features.getFx,
})

// TODO: refactor (make reusable header)
sample({
  clock: [init, preferences.$language],
  source: {
    isNotAuthenticated: session.$isNotAuthenticated,
    language: preferences.$language,
  },
  fn: ({ isNotAuthenticated, language }) => {
    const languageId = language?.id
    let headers = {}

    if (isNotAuthenticated && languageId) {
      headers = {
        [SPECIAL_HEADER.CLIENT_PRIMARY_LANGUAGE]: String(languageId),
      }
    }
    return { headers } as HeaderFxParameter
  },
  target: modules.getModulesFx,
})
