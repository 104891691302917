/*
 * Different useful boolean helpers
 */

/**
 * Always returns `true`
 */
export const T = (): boolean => true

/**
 * Always returns `false`
 */
export const F = (): boolean => false

/**
 * Cast any value to boolean
 */
export const aye = (value?: unknown): boolean => Boolean(value)

/**
 * Cast inverted value to boolean
 */
export const nay = (value?: unknown): boolean => !value

/**
 * Checks if argument is not null or undefined
 */
export const exists = <T>(x?: T | null | undefined): x is T => x != null
