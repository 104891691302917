import { env } from '~/app/environment'

const firebaseDevConfig = {
  apiKey: 'AIzaSyDA9DMcpGXzPeZ2hNzLLPCrowFqf-fHow0',
  authDomain: 'uvotv-all-website-data.firebaseapp.com',
  projectId: 'uvotv-all-website-data',
  storageBucket: 'uvotv-all-website-data.appspot.com',
  messagingSenderId: '743012131049',
  appId: '1:743012131049:web:8c83c27cdfe10516649078',
  measurementId: 'G-0XBWFVT6SX',
}

const firebaseProdConfig = {
  apiKey: 'AIzaSyDA9DMcpGXzPeZ2hNzLLPCrowFqf-fHow0',
  authDomain: 'uvotv-all-website-data.firebaseapp.com',
  projectId: 'uvotv-all-website-data',
  storageBucket: 'uvotv-all-website-data.appspot.com',
  messagingSenderId: '743012131049',
  appId: '1:743012131049:web:b616aeb9e0f7ab92649078',
  measurementId: 'G-TTP4Y9DPFL',
}

export const firebaseConfig =
  env.UVO_FIREBASE_CONFIG === 'production'
    ? firebaseProdConfig
    : firebaseDevConfig
