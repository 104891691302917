import { logger } from '@/logger'
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector'
import { type FirebaseApp } from 'firebase/app'
import {
  fetchAndActivate as fbFetchAndActivate,
  getRemoteConfig,
  type RemoteConfig,
} from 'firebase/remote-config'
import { delay } from 'patronum'
import { env } from '~/app/environment'

export const init = createEvent<FirebaseApp>()
export const done = createEvent<void>()
export const setDefaults = createEvent<RemoteConfig['defaultConfig']>()

// creates firebase remote config
const getConfigFx = createEffect<FirebaseApp, RemoteConfig>(getRemoteConfig)

export const $firebaseConfig = createStore<RemoteConfig | null>(null)
  .on(getConfigFx.doneData, (_, config) => {
    config.settings.minimumFetchIntervalMillis =
      env.UVO_CLIENT_FIREBASE_FETCH_INTERVAL
    return config
  })
  .on(setDefaults, (cfg, defaults) => {
    if (cfg) cfg.defaultConfig = defaults
    return cfg
  })

// get and activate remote config effect
export const fetchAndActivateFx = attach({
  source: $firebaseConfig,
  effect: async (config) => {
    if (config) {
      await fbFetchAndActivate(config)
    }
  },
})

// init firebase remote config
sample({
  clock: init,
  target: getConfigFx,
})

// trigger done in any case after successfully or failed initialization
sample({
  clock: getConfigFx.finally,
  target: done,
})

// log error if remote config fetch failed
sample({
  clock: fetchAndActivateFx.failData,
  fn: (error) => ['firebase remote config fetch failed:', error],
  target: logger.errorFx,
})

// set interval for remote config updates
if (process.env.NODE_ENV === 'production') {
  sample({
    clock: delay({
      source: fetchAndActivateFx.finally,
      timeout: env.UVO_CLIENT_FIREBASE_FETCH_INTERVAL,
    }),
    target: fetchAndActivateFx,
  })
}
