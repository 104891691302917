export const HEADER = {
  UVO_PLATFORM: 'Uvo-Platform',
  UVO_APP_NAME: 'Uvo-App-Name',
  UVO_DEVICE_TYPE: 'Uvo-Device-Type',
  UVO_DEVICE_OS: 'Uvo-Device-Os',
  UVO_DEVICE_OS_VERSION: 'Uvo-Device-Os-Version',
  UVO_DEVICE_APP_VERSION: 'Uvo-Device-App-Version',
  UVO_WEB_METADATA: 'Uvo-Web-Metadata',
}

export enum SPECIAL_HEADER {
  CLIENT_PRIMARY_LANGUAGE = 'client-primary-language',
}

export const APP_PLATFORM = 'WEB'

export type HeaderFxParameter = {
  headers: Headers
}
