import type {
  Creator,
  CreatorSocialMediaLink,
  CreatorTag,
} from '@setplex/tria-api'
import { toDate } from '../../tools'
import { format as formatContent } from '../content/format'
import type {
  ApiCreator,
  ApiCreatorSocialMediaLink,
  ApiCreatorTag,
} from './index.h'

const formatSocialLink = (
  link: ApiCreatorSocialMediaLink
): CreatorSocialMediaLink => ({
  alias: link.socialMedia.alias,
  title: link.socialMedia.title,
  link: link.link,
})

export const format = ({
  id,
  name,
  avatar,
  about,
  background,
  createDate,
  organization,
  organizationDescription,
  content,
  socialMediaLinks,
  awards,
}: ApiCreator): Creator => ({
  id,
  name,
  avatar,
  about,
  background,
  createDate: toDate(createDate),
  organization,
  organizationDescription,
  content:
    content && content.length ? content.map(formatContent) : (content as any),
  links:
    socialMediaLinks && socialMediaLinks.length
      ? socialMediaLinks.map(formatSocialLink)
      : (socialMediaLinks as any),
  awards,
})

export const formatCreatorTag = ({
  id,
  title,
  description,
  horizontalImage,
  background,
  content,
  createDate,
  sortOrder,
}: ApiCreatorTag): CreatorTag => ({
  id,
  title,
  description,
  horizontalImage,
  background,
  content: content?.results?.length
    ? content.results.map(formatContent)
    : (content?.results as any),
  createDate: toDate(createDate),
  sortOrder,
})
