import { type LiveChannel } from '@setplex/tria-api'

export const NO_EPG_ID = '0'

// instead of `TimeRange` = { start: number, end: number } -> just immutable tuple
export type Range = readonly [number, number]

// params for epg request
export type EpgRequestParams = {
  channels: LiveChannel[]
  period?: Range | null
}

// enum, indicating, whether time range is live, past or future
export enum State {
  Elapsed, // past
  Ongoing, // live
  Upcoming,
}

// enum, indicating, whether time range is before yesterday, yesterday, today, tomorrow or after tomorrow
export enum Day {
  Ereyesterday,
  Yesterday,
  Today,
  Tomorrow,
  Overmorrow,
}

// range position in time, relative to exact time moment "now"
export type RangePosition = {
  state: State
  starts: Day
  ends: Day
}
