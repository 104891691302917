import { Logger } from '@/logger'
import { api } from '@setplex/tria-api'
import { adapter, setLogger } from '@setplex/tria-api-adapter-ovp'
import { env } from '~/app/environment'
import { request } from './request'
export { SPECIAL_HEADER, type HeaderFxParameter } from './index.h'

api.use(adapter)
api.fx.use(request)

// set logger for a adapter
if (env.UVO_CLIENT_ADAPTER_DEBUG) {
  setLogger(new Logger('adapter'))
}

export * as int from './internal'
