import { logger } from '~/shared/logger'

interface Props {
  error: Error
}

ErrorFallback.displayName = 'ErrorFallback'
export function ErrorFallback({ error }: Props): JSX.Element {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message || String(error)}</pre>
    </div>
  )
}

export function errorHandler(
  error: Error,
  info: { componentStack: string }
): void {
  // log error to sentry or whatever
  logger.error('❤️‍🩹', error, info)
}
